import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import get_url from "./common/ApiCaller";
import EventDetails from "./EventDetails";
import $ from "jquery";

import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";

const EditGetStarted = (props) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [categoryId, setcategoryId] = useState(""); //by default null
  let tempArr = [];
  const event_id = localStorage.getItem("event_id");
  const [items, setItems] = useState([]);
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  let itemsSelected = [];
  const arr = [];
  selectedCategory.map((data) => {
    itemsSelected.push(data?.id);
  });
  const handleSelect = (selectedList) => {
    var id = selectedList.target.getAttribute("data-id");
    if (-1 < itemsSelected.indexOf(id)) {
      itemsSelected.splice(itemsSelected.indexOf(id), 1);
    } else {
      itemsSelected.push(id);
    }
  };

  const handleRemove = (selectedList) => {
    setItems(selectedList);
  };

  const onChange = (event) => {
    const getcategoryid = event.target.value;
    setcategoryId(getcategoryid);
  };
  // window.open( $("input").each(function () {
  //   let attr = $(this).attr("data-id");

  //   for (var i = 0; i < itemsSelected.length; i++) {
  //     if (itemsSelected[i] == attr) {
  //       $( this ).attr( 'checked', true )
  //     }
  //   }
  // }));

  useEffect(() => {
    $("input").each(function () {
      let attr = $(this).attr("data-id");

      for (var i = 0; i < itemsSelected.length; i++) {
        if (itemsSelected[i] == attr) {
          $(this).attr("checked", true);
        }
      }
    });
  });

  const handleSubmit = async () => {
    if (itemsSelected.length > 0) {
      localStorage.setItem("selected_category", JSON.stringify(itemsSelected));
      let category_data = {
        multi_cat_data: itemsSelected,
      };
      await axios
        .post(
          `${get_url.local_url}events/updateEvent`,
          category_data,
          header
        )
        .then((response) => {
          // alert("Items Selected Successfully")

          navigate("/CheckList/" + event_id);
        })
        .catch((error) => console.log(error));
    } else {
      alert("Please Select atleast one category");
    }
  };

  const fetch_static_category_data = async () => {
    await axios
      .get(`${get_url.local_url}catSubCat/categoriesList`)
      .then((response) => {
        const all_category_data = response.data.data;
        // if(localStorage.getItem('selected_category') != null ){
        //    navigate('/Dashboard')
        // STORE FOR LATTER USE @NEEL.B@
        let selected_category_id = JSON.parse(
          localStorage.getItem("selected_category")
        );
        var arr_selected_cat = [];
        all_category_data?.map((cat_data) => {
          if (selected_category_id.includes(cat_data._id)) {
            let store_cat_data = {
              id: cat_data._id,
              name: cat_data.name,
            };
            arr_selected_cat.push(store_cat_data);
          }
        });
        setSelectedCategory(arr_selected_cat);
        //}

        if (response.status === 200) {
          setSelectedValue(all_category_data);
        } else {
          alert("Something went wrong");
          return false;
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetch_static_category_data();
  }, []);

  return (
    <>
      <section className="get_start get-1">
        <div className="container">
          {/* <EventDetails /> */}
          <div className="get_heading">
            <h1>Get started by building a plan of action</h1>
            <div className="multi_selector">
              <p className="p_text">
                Choose all event items your event will need
              </p>
              <div className="mt-5">
                <div
                  className="checkbox_getstarted row"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {selectedValue.map((data) => {
                    return (
                      <div className="col-lg-4 col-md-6 ">
                        <input
                          type="checkbox"
                          className="btn-check"
                          name="btnradio"
                          id={"btnradio" + data._id}
                          onClick={handleSelect}
                          data-id={data._id}
                        />

                        <label
                          className="btn btn-outline-primary btn_checkbox_option"
                          for={"btnradio" + data._id}
                        >
                          {data.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row custom_retal7">
            <div className="btn_outer poular_outer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Populate my checklist
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditGetStarted;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import {
  useCreateEventStep1Mutation,
  useEventDetailsQuery,
} from "../Redux/Slices.js/EventSlice";
import Loader from "../Component/Loader";

const Step2 = (props) => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [eventMode, setEventMode] = useState(["In Person", "Online", "Hybrid"]);
  const [loading, setLoading] = useState(false);
  const { data: eventDetails } = useEventDetailsQuery();
  const [createEventStep1] = useCreateEventStep1Mutation();
  const [selectedEventMode, setSelectedEventMode] = useState("");
  const [items, setItems] = useState(""); // Define and set 'items' here

  const handleRadioButton = (event_mode) => {
    setSelectedEventMode(event_mode);
  };

  const submitForm = () => {
    setLoading(true);
    if (!selectedEventMode) {
      toast("Please select any event mode first.");
      setLoading(false);
    } else {
      const event_data = {
        event_mode: selectedEventMode,
        event_id: localStorage.getItem("event_id"),
      };

      createEventStep1(event_data)
        .then((response) => {
          setLoading(false);
          navigate("/step3");
        })
        .catch((err) => {
          setLoading(false);
          toast(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchEventData = async () => {
    const event_id = localStorage.getItem("event_id");
    if (event_id === null) {
      navigate("/PricingPlans");
    }

    const get_event_data = eventDetails?.data;

    // if (typeof get_event_data === "undefined") {
    //   navigate("/Dashboard");
    // }
    setEventData(get_event_data);
    setSelectedEventMode(get_event_data?.event_mode);
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  useEffect(() => {
    // Define and set 'items' here, for example:
    const items = localStorage.getItem("selected_category");
    setItems(items);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 2 of 7</div>
      </div>

      <section className="planing_outer">
        <div className="container">
          <Link to={items ? "/EditStep1" : "/step1"}>
            {" "}
            <i
              style={{ fontSize: "50px" }}
              className="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>What is your event mode?</h4>
          </div>
          <div className="row">
            <div className="estimate-5">
              <div className="text_outer">{/* # of Estimated Attendees */}</div>
              <div className="row">
                {eventMode.length > 0 &&
                  eventMode.map((event, index) => (
                    <div className="col-lg-4 col-md-4" key={index}>
                      <div
                        className={`even_box1 ${
                          selectedEventMode ===
                          event.toLowerCase().replace(" ", "_")
                            ? "active-border"
                            : ""
                        }`}
                        onClick={(e) => {
                          handleRadioButton(
                            event.toLowerCase().replace(" ", "_")
                          );
                        }}
                      >
                        <label className="radiocontainer">
                          <input
                            type="radio"
                            className="ischeck prev_valued radio_data"
                            value={event.toLowerCase().replace(" ", "_")}
                            name="event_platform"
                            checked={
                              selectedEventMode ===
                              event.toLowerCase().replace(" ", "_")
                            }
                          />
                          <span className="checkmark"></span>
                          <h3>{event}</h3>
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="p_text">You can always edit it later</div>
              <div className="btn_outer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => {
                    submitForm();
                  }}
                >
                  {" "}
                  Continue{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step2;

import React from "react";
import "../../SuperAdmin/css/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";
import { Post_Method } from "../../Services/services.midlware";
const AddCategoryModal = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    Post_Method("catSubCat/add-category", data)
      .then((response) => {
        const { data } = response;
        props.setShow(false);
        toast.success(data.message);
        setValue("name", ""); // Reset the 'name' field
        setValue("status", "Select Option"); // Reset the 'status' field
        props.getAllCategory();
      })
      .catch((error) => {
        throw error;
      });
    // const headers = {
    //     "Access-Control-Allow-Origin": "*",
    //     'Content-Type': 'multipart/form-data',
    // }
    // await axios.post(`${get_url.local_url}add_category`, data, headers )
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
                placeholder="Enter Category Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("status", { required: true })}
              >
                <option>Select Option</option>
                <option value="true">Active</option>
                <option value="false">Deactive</option>
              </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddCategoryModal;

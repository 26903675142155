import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import moment from "moment";

const EventDetails = (props) => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [eventType, setEventType] = useState(
    localStorage.getItem("event_type")
  );
  const { event_id } = useParams();

  const handleLogout = () => {
    window.localStorage.clear();
    toast.success("User Logout Successfully");
    navigate("/Login");
  };
  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id")
      ? localStorage.getItem("event_id")
      : 0;
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response?.data?.data;
        setEventData(get_event_data);
      });
  };

  useEffect(() => {
    if (event_id != undefined) {
      fetchEventData();
    }
  }, []);
  return (
    <>
      <div className="">
        <div className="input-boxes-id Top_detail_box row">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <Link className="navbar-brand easy_event_logo" to="/">
              <h5 className="easy_event">Easy Events</h5>
            </Link>
          </div>
          <div className="row detail_con col-lg-7 col-md-6 col-sm-12">
            <div className="Detail_box" id="headingOne">
              <button
                class="btn clickme col-lg-2 col-md-6 col-sm-12 "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Event Details
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="row detail_con">
                <div className="col-lg-3 col-md-3">
                  <div className="create_outer1 new_cre4">
                    <p>Event </p>
                    <input
                      className="form-control input-boxes"
                      type="text"
                      value={eventData?.event_name}
                      readOnly
                    ></input>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="create_outer1 new_cre4">
                    <p>Start Date </p>
                    <input
                      className="form-control input-boxes"
                      value={
                        eventData?.start_date
                          ? moment(eventData?.start_date).format("DD/MM/YYYY")
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="create_outer1 new_cre4">
                    <p>City </p>
                    <input
                      className="form-control input-boxes"
                      type="text"
                      value={eventData?.city}
                      readOnly
                    ></input>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="create_outer1 new_cre4">
                    <p>Size </p>
                    <input
                      className="form-control input-boxes"
                      type="text"
                      value={eventData?.event_size}
                      readOnly
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 d-flex row align-items-end">
            <Dropdown className="col-lg-4 col-md-4 col-sm-3">
              <Dropdown.Toggle
                className="create_outer1 new_cre4 profile_toggle"
                id="dropdown-basic"
              >
                <img alt="User" src="/Assets/img/Icon_Profile.png" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="Profile_Options">
                <Dropdown.Item className="Profile_Option" href="#/action-1">
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <i class="Profile_icons fas fa-user-circle"></i> Profile{" "}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="Profile_Option" href="#">
                  <Link
                    to="/Dashboard"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <i class="fa-solid fa-table-columns"></i> &nbsp; Dashboard{" "}
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="Profile_Option" href="#/action-2">
                  <Link
                    to="/ResetPassword"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <i class="Profile_icons fas fa-lock"></i> Change Password
                  </Link>
                </Dropdown.Item>

                {/* <Link to="/Logout" onClick={handleLogout}>Logout</Link> */}
                <Dropdown.Item
                  className="Profile_Option"
                  onClick={handleLogout}
                >
                  <i class="Profile_icons fas fa-sign-out-alt"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="create_outer1 new_cre4 col-lg-8 col-md-8 col-sm-9">
              {localStorage.getItem("role") == "admin" ? (
                <Link
                  to="/step1"
                  className="btn Help-center-btn create_event_butn"
                  onClick={() => {
                    localStorage.removeItem("event_id");
                    localStorage.removeItem("selected_category");
                    localStorage.removeItem("selectedEventType");
                  }}
                  type="button"
                >
                  Create Event
                </Link>
              ) : null}
            </div>
          </div>
          {eventData && Object.keys(eventData).length > 0 && (
            <div
              className={`col-lg-2 col-md-6 col-sm-4 H_btn ${
                userRole === "user" || eventType == "close_event"
                  ? "d-none"
                  : ""
              }`}
            >
              <div  onClick={()=>{ navigate("/EditStep1");}} className="create_outer1 new_cre4">
                <Link
                 
                  className="btn Help-center-btn"
                  type="button"
                >
                  Edit Event
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EventDetails;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Switch,
} from "react-router-dom";
//import AdminRoute from '../actions/AdminRoute';
import "react-toastify/dist/ReactToastify.css";

import "./App/public/css/style.css";
import "./App/public/css/home.css";

import AdminRoute from "./events/common/AdminRoute";
import SuperAdminRoute from "./events/common/SuperAdminRoute";
import Step1 from "./events/step1";
import Step2 from "./events/step2";
import Step3 from "./events/step3";
import Step4 from "./events/step4";
import Step5 from "./events/step5";
import Step6 from "./events/step6";
import Step7 from "./events/step7";
import EventSummary from "./events/EventSummary";
import GetStarted from "./events/GetStarted";
import CheckList from "./events/CheckList";
import Contacts from "./events/Contacts";
import Budgets from "./events/Budgets";
import TeamManagement from "./events/TeamManagement";
import Dashboard from "./events/Dashboard";
import DashboardUsers from "./events/DashboardUser";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import Home from "./events/Home";
import AboutUs from "./components/AboutUs";
import PricingPlans from "./components/PricingPlans";
import BlogListings from "./Blogs/BlogListings";
import BlogDetails from "./Blogs/BlogDetails";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Welcome from "./events/Welcome";
import Profile from "./Authentication/Profile";
import ResetPassword from "./Authentication/ResetPassword";
import ForgotPassword from "./Authentication/ForgotPassword";
import ChangeForgotPassword from "./Authentication/ChangeForgotPassword";
import PaymentSuccess from "./components/PaymentSuccess";

import Signin from "./Authentication/Signin";
import Dashboard1 from "./SuperAdmin/Dashboard/Dashboard1";
import AdminResetPassword from "./SuperAdmin/Dashboard/AdminResetPassword";
import Events from "./SuperAdmin/Events";
import Users from "./SuperAdmin/Users";
import Category from "./SuperAdmin/Category";
import Subcategory from "./SuperAdmin/Subcategory";
import ChecklistData from "./SuperAdmin/Task";
import Subscription from "./SuperAdmin/Subscription";
import Blogs from "./SuperAdmin/Blogs";
import AddBlog from "./SuperAdmin/Blogs/AddBlog";
import AdminUpdateProfile from "./SuperAdmin/Dashboard/AdminUpdateProfile";
import PaymentFailed from "./components/PaymentFailed";
import EditStep1 from "./events/EditStep1";
import EditGetStarted from "./events/EditGetStarted";
import VerifyMail from "./Authentication/VerifyMail";
import Check from "./events/Check";

// import CreatingEvent from "./events/CreatingEvent";

//
// import DateChecklist from "./events/DateChecklist";

// import Budgets from './Budgets/Budgets';

//
// import Logout from "./Authentication/Logout"

// import Welcome from "../containers/About/Welcome";

// import BlogDetails from "./Blogs/BlogDetails";
// import Payments from "./Payments/Payments";
// import Signin from "./Authentication/Signin";

// import Dashboard1 from "./SuperAdmin/Dashboard/Dashboard1";
// import SuperAdminEvents from "./SuperAdmin/Events/SuperAdminEvents";
// import SuperAdminChecklist from "./SuperAdmin/Checklist/SuperAdminChecklist";
// import SuperAdminUsers from "./SuperAdmin/Users";
// import AddNewEvent from "./SuperAdmin/Events";
// import EditEvent from "./SuperAdmin/Events/EditEvent";
// import Subscription from "./SuperAdmin/Subscription";
// import Events from "./SuperAdmin/Events";
// import Users from "./SuperAdmin/Users";
// import Blogs from "./SuperAdmin/Blogs";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/step1"
            element={
              <AdminRoute>
                <Step1 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/EditStep1"
            element={
              <AdminRoute>
                <EditStep1 />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/step2"
            element={
              <AdminRoute>
                <Step2 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/step3"
            element={
              <AdminRoute>
                <Step3 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/step4"
            element={
              <AdminRoute>
                <Step4 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/step5"
            element={
              <AdminRoute>
                <Step5 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/step6"
            element={
              <AdminRoute>
                <Step6 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/step7"
            element={
              <AdminRoute>
                <Step7 />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/EventSummary"
            element={
              <AdminRoute>
                <EventSummary />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/GetStarted"
            element={
              <AdminRoute>
                <GetStarted />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/EditGetStarted"
            element={
              <AdminRoute>
                <EditGetStarted />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/CheckList/:event_id"
            element={
              <AdminRoute>
                <Check />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Check/:event_id"
            element={
              <AdminRoute>
                <Check />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Contacts/:event_id"
            element={
              <AdminRoute>
                <Contacts />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Budgets/:event_id"
            element={
              <AdminRoute>
                <Budgets />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/TeamManagement/:event_id"
            element={
              <AdminRoute>
                <TeamManagement />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Profile"
            element={
              <AdminRoute>
                <Profile />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/ResetPassword"
            element={
              <AdminRoute>
                <ResetPassword />
              </AdminRoute>
            }
          />

          <Route
            exact
            path="/DashboardUsers"
            element={
              <AdminRoute>
                <DashboardUsers />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Dashboard"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/Dashboard/:event_id"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/verifyMail/:id" element={<VerifyMail />} />

          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/PricingPlans" element={<PricingPlans />} />
          <Route exact path="/BlogListings" element={<BlogListings />} />
          <Route
            exact
            path="/BlogListings/:blog_id"
            element={<BlogDetails />}
          />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/Welcome" element={<Welcome />} />
          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route
            exact
            path="/ChangeForgotPassword/:user_id"
            element={<ChangeForgotPassword />}
          />
          <Route exact path="/payment-success" element={<PaymentSuccess />} />
          <Route exact path="/payment-failed" element={<PaymentFailed />} />

          <Route exact path="/Signin" element={<Signin />} />
          <Route
            exact
            path="/Dashboard1"
            element={
              <SuperAdminRoute>
                <Dashboard1 />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Events"
            element={
              <SuperAdminRoute>
                <Events />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Users"
            element={
              <SuperAdminRoute>
                <Users />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Category"
            element={
              <SuperAdminRoute>
                <Category />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Sub-category"
            element={
              <SuperAdminRoute>
                <Subcategory />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Checklist-Data"
            element={
              <SuperAdminRoute>
                <ChecklistData />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Subscription"
            element={
              <SuperAdminRoute>
                <Subscription />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/Blogs"
            element={
              <SuperAdminRoute>
                {" "}
                <Blogs />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/AddBlog"
            element={
              <SuperAdminRoute>
                <AddBlog />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/AdminResetPassword"
            element={
              <SuperAdminRoute>
                <AdminResetPassword />
              </SuperAdminRoute>
            }
          />
          <Route
            exact
            path="/AdminUpdateProfile"
            element={
              <SuperAdminRoute>
                <AdminUpdateProfile />
              </SuperAdminRoute>
            }
          />

          {/*    
              <Route exact path="/CreatingEvent" element={<CreatingEvent/>}/>
              <Route exact path="/DateChecklist" element={<DateChecklist/>}/>
              
              <Route exact path="/ForgotPassword" element={<ForgotPassword/>}/>
 
              <Route exact path="/Admin-Dashboard" element={<Dashboard1/>}/>
              <Route exact path="/Dashboard1" element={<Dashboard1/>}/>
              <Route exact path="/SuperAdminEvents" element={<SuperAdminEvents/>}/>
              <Route exact path="/SuperAdminUsers" element={<SuperAdminUsers/>}/>
              <Route exact path="/SuperAdminChecklist" element={<SuperAdminChecklist/>}/>
              <Route exact path="/EditEvent" element={<EditEvent/>}/>
              <Route exact path="/AddNewEvent" element={<AddNewEvent/>}/>
              <Route exact path="/Subscription" element={<Subscription/>}/>
              <Route exact path="/Events" element={<Events/>}/>
              <Route exact path="/Users" element={<Users/>}/>
              <Route exact path="/Blogs" element={<Blogs/>}/>

              <Route exact path="/" element={<Home/>}/>
              
              
                 
              
              <Route exact path="/Budgets" element={<Budgets/>}/> 
              <Route exact path="/Logout" element={<Logout/>}/>
              <Route exact path="/AboutUs" element={<AboutUs/>}/>
              <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
              <Route exact path="/PricingPlans" element={<PricingPlans/>}/>
              <Route exact path="/BlogDetails" element={<BlogDetails/>}/>
              
              <Route exact path="/Payments" element={<Payments/>}/>
              <Route exact path="/Signin" element={<Signin/>}/> 

              <Route exact path="/Dashboard1" element={<Dashboard1/>}/>
              <Route exact path="/SuperAdminEvents" element={<SuperAdminEvents/>}/>
              <Route exact path="/SuperAdminUsers" element={<SuperAdminUsers/>}/>
              <Route exact path="/SuperAdminChecklist" element={<SuperAdminChecklist/>}/>
              <Route exact path="/EditEvent" element={<EditEvent/>}/>
              <Route exact path="/AddNewEvent" element={<AddNewEvent/>}/>


              <Route exact path='/' element={<Login/>} />  */}

          {/* ADMIN ROUTES */}
          {/* <Route path="/admin" element={ <AdminRoute> <AdminDashboard /> </AdminRoute> } />
           */}
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useChangePasswordMutation } from "../Redux/Slices.js/AuthSlice";
import Loader from "../Component/Loader";
import get_url from "../events/common/ApiCaller";
import axios from "axios";

const ChangeForgotPassword = () => {
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: yup
      .string()
      .required("Please Confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  let url = `${get_url.local_url}change-forget-password`;
  const onSubmit = async (data) => {
    setLoading(true);
    let user_id = window.location.href.split("/")[4];
    if (data.password === data.confirm_password) {
      let reset_password_data = {
        password: data.password,
        confirm_password: data.confirm_password,
        user_id: user_id,
      };
      await axios
        .post(url, reset_password_data, header)
        // changePassword(reset_password_data)
        .then((response) => {
          setLoading(false);
          if (response.data.status === "1") {
            navigate("/Login");
            toast.success("Password Change Successfully");
          } else {
            toast.success(response.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
      reset();
    } else {
      toast.error("Password and change password doestn't match");
    }
  };
  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="signup_container shadow">
          <div className="image_con">
            <div className="motive_text overlay">
              <h2>Manage your events perfectly</h2>
            </div>
          </div>

          <div className="form_con">
            <div className="action_form">
              <h2>Create New password</h2>
              <p className="highlight_form_text">Please reset your password </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Password"
                      name="password"
                      {...register("password", { required: true })}
                      autoComplete="off"
                    />
                    <p className="custom-error">{errors.password?.message}</p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      {...register("confirm_password", { required: true })}
                      autoComplete="off"
                    />
                    <p className="custom-error">
                      {errors.confirm_password?.message}
                    </p>
                  </Form.Group>
                </Row>

                <Button className="btn button_mg" type="submit">
                  Reset Password
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeForgotPassword;

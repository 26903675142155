import React from "react";
import "../../SuperAdmin/css/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";
import { Post_Method } from "../../Services/services.midlware";

const SubscriptionModal = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    await Post_Method("subscription/add_subscription", data)
      .then((response) => {
        if (200 !== response.data.statusCode) {
          alert(response.data.message);
          return false;
        }
        toast(response.data.message);
        reset();
        props.setShow(false);
        props.getAllSubscription();
      })
      .catch((error) => {
        toast(error.response.data.message);
        throw error;
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                {...register("plan_name", { required: true })}
                placeholder="Enter Plan Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                {...register("plan_amount", { required: true })}
                placeholder="Plan Amount"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Event Created</Form.Label>
              <Form.Control
                type="number"
                {...register("create_events", { required: true })}
                placeholder="Event Created"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Team Member</Form.Label>
              <Form.Control
                type="number"
                {...register("team_member", { required: true })}
                placeholder="Team Member"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Role Based Acces To Team</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("role_based_access", { required: true })}
              >
                <option>Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Automated Checklist</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("automated_checklist", { required: true })}
              >
                <option>Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Budget Tool</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("budget", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Checklist Reminder</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("checklist_reminder", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Crm Access</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("is_crm", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email Support</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("email_support", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Chat Support</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("chat_support", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Free Training</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("training", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Additional Feature</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("additional_feature", { required: true })}
              >
                <option value="">Select Option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              <Button variant="secondary" onClick={props.handleClose}>
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SubscriptionModal;

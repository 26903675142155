import React, { useState, useRef, useEffect, Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import get_url from "../../events/common/ApiCaller";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { Get, Delete } from "../../Services/services.midlware";
import { Modal } from "react-bootstrap";

const Index = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const handleDeleteBlog = async (row) => {
    setSelectedBlog(row);
    setShowModal(true);
  };
  function removeHtmlTags(input) {
    const regex = /(<([^>]+)>)/gi;
    return input.replace(regex, "");
  }

  const deleteConfirmed = () => {
    Delete(`blogs/remove/${selectedBlog._id}`)
      .then((resp) => {
        getAllBlogs();
        toast.success(resp.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setShowModal(false);
        setSelectedBlog(null);
      });
  };
  // const handleDeleteBlog = async (row) => {
  //   Delete(`blogs/remove/${row._id}`)
  //     .then((resp) => {
  //       getAllBlogs();
  //       toast.success(resp.data.message);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  const columns = [
    {
      name: "#Sno.",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: "Blog Title",
      selector: (row) => row.blog_title,
      sortable: true,
    },
    {
      name: "Blog Image",
      selector: (row) => (
        <img
          src={get_url.local_url + `upload/` + row.blog_img_link}
          style={{ height: "100px", width: "100px" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Blog Description",
      selector: (row) => removeHtmlTags(row.blog_description),
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row, index) => (
        <button
          className=" btn action_btn"
          onClick={() => handleDeleteBlog(row)}
        >
          <i class="fas fa-trash"></i>
        </button>
      ),
    },
  ];

  const getAllBlogs = async () => {
    await Get("blogs/blogList")
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <>
      <AdminHeader />

      <div className="container-fluid">
        <div className="row">
          <AdminSideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list Page_box">
              <div className="event_list_head mb-3 p-2">
                <div className="eve_list_heading">
                  <h5>Blogs</h5>
                </div>
                <hr className="dropdown-divider" />
                <div className="see_all">
                  <div>
                    <Link
                      variant="primary"
                      to="/AddBlog"
                      className="btn btn-light"
                    >
                      Create new blog
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="events_list Page_box_subscription">
              <DataTable
                title="Blogs"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
              />
            </div>
          </main>
        </div>
      </div>
      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the blog with title "
          {selectedBlog?.blog_title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;

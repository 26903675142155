import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import get_url from "../events/common/ApiCaller";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../Redux/Slices.js/AuthSlice";
import Loader from "../Component/Loader";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [forgetPassword, isError] = useForgetPasswordMutation();
  const [loading, setLoading] = useState(false);
  const naviagte = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const forget_password_data = {
      email: data.email,
    };
    forgetPassword(forget_password_data)
      .then((response) => {
        setLoading(false);

        if (response.data.status == "1") {
          toast.success(response?.data?.message);
          naviagte("/login");
        } else {
          toast.error(response.error.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    reset();
  };
  useEffect(() => {
    if (isError) {
      toast.error(isError?.error?.data?.message);
    }
  }, [isError]);
  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="signup_container shadow">
          <div className="image_con">
            <div className="motive_text overlay1">
              <h2>Manage your events perfectly</h2>
            </div>
          </div>

          <div className="form_con">
            <div className="action_form">
              <h2>Forgot Password</h2>
              <p className="highlight_form_text">Please reset your password </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      className="E_mail"
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      {...register("email")}
                    />
                    <p className="custom-error">{errors.email?.message}</p>
                  </Form.Group>
                </Row>

                <Button className="btn button_mg" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import "../../SuperAdmin/css/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";
import { Get, Post_Method } from "../../Services/services.midlware";

const AddSubCategoryModal = (props) => {
  const [categroy, setCategory] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    await Post_Method("catSubCat/add-subCategory", data)
      .then((response) => {
        const { data } = response;
        setValue("name", "");
        setValue("list_category", "Select Category");
        props.setShow(false);
        toast.success(data.message);
        props.getAllSubCategory();
      })
      .catch((error) => {
        throw error;
      });
  };

  const getAllCategory = async () => {
    Get("catSubCat/getCategories").then((resp) => {
      setCategory(resp.data.data);
    });
  };

  useEffect(() => {
    getAllCategory();
  }, []);
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Select Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("list_category", { required: true })}
              >
                <option selected>Select Category</option>
                {categroy.length > 0 &&
                  categroy.map((data) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Sub Category Name</Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
                placeholder="Enter Sub Category Name"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddSubCategoryModal;

import React, { useEffect, useState } from "react";
import "../../SuperAdmin/css/style.css";
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import Button from "react-bootstrap/Button";
import SubscriptionModal from "./addSubcriptionModal";
import EditSusbcription from "./editModal";
import DataTable from "react-data-table-component";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { Get, Delete } from "../../Services/services.midlware";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const Index = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [editSub, setEditSub] = useState(false);
  const handleEditClose = () => setEditSub(false);
  const handleEditShow = () => setEditSub(true);
  const [newData, setNewData] = useState(false);
  const [plan, setPlan] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleEditSubscription = (data) => {
    setPlan(data);
    handleEditShow();
  };

  const handleDeleteSubscription = (row) => {
    setSelectedSubscription(row);
    setShowDeleteModal(true);
  };

  const deleteConfirmed = async () => {
    try {
      const resp = await Delete(
        "subscription/deleteSubscription/" + selectedSubscription._id
      );
      if (resp.data.status) {
        toast.success(resp.data.message);
        getAllSubscription();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setShowDeleteModal(false);
      setSelectedSubscription(null);
    }
  };

  // const handleDeleteSubscription = async (row) => {
  //   const resp = await Delete("subscription/deleteSubscription/" + row._id);
  //   if (resp.data.status) {
  //     alert(resp.data.message);
  //     // toast.success(resp.data.message);
  //     getAllSubscription();
  //   }
  // };

  const columns = [
    {
      name: "#Sno.",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: "Plan Name",
      selector: (row) => row.plan_name,
      sortable: true,
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Plan Amount",
      selector: (row) => `$${row.plan_amount}`,
      sortable: true,
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Events",
      selector: (row) => row.create_events,
      sortable: true,
      maxWidth: "auto",
    },
    {
      name: "Team Member",
      selector: (row) => row.team_member,
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Role Access",
      selector: (row) => (row.role_based_access == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Checklist",
      selector: (row) => (row.automated_checklist == 1 ? "Yes" : "No"),
      maxWidth: "auto",
    },
    {
      name: "Budget Tool",
      selector: (row) => (row.budget == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Checklist Reminder",
      selector: (row) => (row.checklist_reminder == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "10%",
    },
    {
      name: "CRM",
      selector: (row) => (row.is_crm == 1 ? "Yes" : "No"),
      maxWidth: "auto",
    },
    {
      name: "Email Support",
      selector: (row) => (row.email_support == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Chat Support",
      selector: (row) => (row.chat_support == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Live Training",
      selector: (row) => (row.training == 1 ? "Yes" : "No"),
      maxWidth: "auto",
      width: "8%",
    },
    {
      name: "Additional Feature",
      selector: (row) =>
        row.additional_feature == "" ? "No Additional Feature" : "Yes",
      maxWidth: "auto",
      width: "11%",
    },

    // {
    //   name: 'Edit',
    //   selector: (row, index) =>  <button  className=" btn action_btn" onClick={() => handleEditSubscription(row)} ><i class="fas fa-edit"></i></button>
    // },
    {
      name: "Delete",
      selector: (row, index) => (
        <button
          className=" btn action_btn"
          onClick={() => handleDeleteSubscription(row)}
        >
          <i class="fas fa-trash"></i>
        </button>
      ),
    },
  ];

  const getAllSubscription = async () => {
    const resp = await Get("subscription/subscriptionList");
    setData(resp.data.data);
  };
  console.log(data, selectedSubscription);
  useEffect(() => {
    getAllSubscription();
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <AdminSideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list">
              <div className="event_list_head su_btn_box">
                <div className="Subscription_btn">
                  <Button variant="primary" onClick={handleShow}>
                    Add Subcription Plan
                  </Button>
                  {/*
                  <Link to="#" variant="primary">See All</Link> */}
                </div>
              </div>
            </div>
            <div className="events_list Page_box_subscription">
              <DataTable
                title="Subscription"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
              />
            </div>
          </main>
        </div>
      </div>
      <SubscriptionModal
        show={show}
        setShow={setShow}
        getAllSubscription={getAllSubscription}
        handleClose={handleClose}
      />
      <EditSusbcription
        editSub={editSub}
        plan={plan}
        setEditSub={setEditSub}
        handleEditClose={handleEditClose}
      />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the subscription plan "
          {selectedSubscription?.plan_name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Index;

import React, { useState } from "react";
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import axios from "axios";
import get_url from "../../events/common/ApiCaller";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Post_Method } from "../../Services/services.midlware";
const AddBlog = () => {
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (value, e) => {
    const formData = new FormData(e.target);
    formData.append("blog_description", data);
    // Display the key/value pairs
    for (var pair of formData.entries()) {
    }
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
    await Post_Method("blogs/add-blog", formData)
      .then((response) => {
        if (!response.data.status) {
          alert(response.data.message);
          return false;
        }
        toast.success(response.data.message);
        navigate("/Blogs");
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        throw error;
      });
  };

  return (
    <>
      <AdminHeader />
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-12 col-sm-12">
            <AdminSideBar />
          </div>

          <div className="col-lg-10 col-md-12 col-sm-12">
            <h3 className="Blog_page_head mt-5 mb-3 text-center ">
              Write Your Blog
            </h3>
            <div>
              <form
                className="row g-3 pt-5 pb-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-md-12">
                  <label className="form-label">Blog Title</label>
                  <input
                    className="form-control form-control-lg"
                    id="blogTitle"
                    {...register("blog_title", { required: true })}
                    placeholder="Blog Title"
                  />
                </div>

                <div className="col-12">
                  <label className="form-label">Featured Image</label>
                  <input
                    type="file"
                    className="form-control"
                    id="featuredImage"
                    {...register("blog_img", { required: true })}
                    placeholder="Featured image"
                    accept="image/*"
                  />
                </div>

                <div className="col-12">
                  <label className="form-label">Blog Content </label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setData(data);
                    }}
                  />
                </div>

                <div className="col-12 text-end">
                  <button type="submit" className="btn btn-primary">
                    Publish Your Blog
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;

import React, { useEffect, useState, usePopup } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Loader from "../Component/Loader";
import { useVerificationMailMutation } from "../Redux/Slices.js/AuthSlice";
import { toast } from "react-toastify";

const VerifyMail = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentURL = window.location.href;
  const urlParts = currentURL.split("/");
  const token = urlParts[urlParts.length - 1];
  const [verificationMail] = useVerificationMailMutation(token);

  const verifyEmail = () => {
    setLoading(true);
    const data1 = {
      token: token,
    };
    verificationMail(data1)
      .then((resp) => {
        setLoading(false);
        toast(resp?.data?.message);
        navigate("/login");
      })
      .catch((err) => {
        setLoading(false);
        toast(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    verifyEmail(token);
  }, [token]);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="signup_container ">
          <div className="image_con">
            <div className="motive_text overlay1">
              <h2 className="Motive_text_tell">Manage your events perfectly</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyMail;

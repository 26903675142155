import React, { useState, useEffect } from "react";
import "../App/public/css/blogs.css";
import MainHeader from "../components/shared/MainHeader";
import Footer from "../components/shared/Footer";
import get_url from "../events/common/ApiCaller";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Get } from "../Services/services.midlware";
import { toast } from "react-toastify";

const BlogDetails = () => {
  const [blogData, setBlogData] = useState([]);
  const [particularBlogData, setParticularBlogData] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);

  const getBlogData = async () => {
    setIsLoaderDisplay(true);
    await Get(`blogs/blogList`)
      .then((response) => {
        setBlogData(response.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const getSelectedBlogData = async (_id) => {
    await Get(`blogs/blogById/${_id}`)
      .then((response) => {
        const get_user_detail = response.data.data;
        setParticularBlogData(get_user_detail);
        setIsLoaderDisplay(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    let _id = window.location.href.split("/")[4];
    getBlogData();
    getSelectedBlogData(_id);
  }, []);
  return (
    <>
      {isLoaderDisplay ? (
        <div className="overlay">
          <div className="center_dots">
            <ThreeDots height="80" width="80" radius="9" color="#0d6efd" />
          </div>
        </div>
      ) : (
        ""
      )}
      <MainHeader />
      <div>
        <div class="blog-single gray-bg">
          <div class="container">
            <div class="row align-items-start">
              <div class="col-lg-8 m-15px-tb">
                <article class="article">
                  <div class="article-img">
                    <img
                      src={
                        get_url.local_url +
                        `upload/` +
                        particularBlogData.blog_img_link
                      }
                      title=""
                      alt=""
                    />
                  </div>
                  <div class="article-title">
                    {/* <h6><a href="#">Lifestyle</a></h6> */}
                    <h2>{particularBlogData.blog_title}</h2>
                    <div class="media">
                      <div class="avatar">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar1.png"
                          title=""
                          alt=""
                        />
                      </div>
                      <div class="media-body">
                        <label>Admin</label>
                        {/* <span>26 FEB 2020</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="article-content">
                    {particularBlogData?.blog_description?.replace(
                      /<[^>]+>/g,
                      ""
                    )}
                  </div>
                </article>
              </div>
              <div class="col-lg-4 m-15px-tb blog-aside">
                <div class="widget widget-latest-post">
                  <div class="widget-title">
                    <h3>Latest Post</h3>
                  </div>
                  <div class="widget-body">
                    {blogData?.length > 0 &&
                      blogData?.map((blog_data) => {
                        return (
                          <div class="latest-post-aside media">
                            <div class="lpa-left media-body">
                              <div class="lpa-title">
                                <h5>
                                  <Link
                                    onClick={"location.reload()"}
                                    to={"/BlogListings/" + blog_data._id}
                                  >
                                    {blog_data.blog_title}
                                  </Link>
                                </h5>
                              </div>
                              <div class="lpa-meta"></div>
                            </div>
                            <div class="lpa-right">
                              <Link to={"/BlogListings/" + blog_data.blog_slug}>
                                <img
                                  src={blog_data.blog_img_link}
                                  title=""
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import AppleLogin from "react-apple-login";
import { useLoginMutation } from "../Redux/Slices.js/AuthSlice";
import Loader from "../Component/Loader";

const Login = (props) => {
  const navigate = useNavigate();
  const [login, isError] = useLoginMutation();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("email name is required"),
    password: yup.string().required("Please Enter your password"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    const data1 = {
      email: data.email,
      password: data.password,
    };

    login(data1)
      .then((response) => {
        setLoading(false);
        if (response.data.token & (response.data.token !== "undefined")) {
          window.localStorage.clear();
          navigate("/");
        }
        if (response.data.statusCode === 200) {
          localStorage.setItem("login_token", response.data.token);
          localStorage.setItem("role", response.data.data.role);
          if (response.data.data.role === "admin") {
            toast.success("Admin Login Successfully !");
            navigate("/Welcome");
          } else if (response.data.data.role === "user") {
            localStorage.setItem("event_id", response.data.data.event_id);
            toast.success("User Login Successfully !");
            navigate("/DashboardUsers");
          } else if (response.data.data.role === "superAdmin") {
            toast.success(response.data?.message);
            localStorage.setItem("tokens", response.data.token);
            localStorage.setItem("role", response.data.role);
            navigate("/Dashboard1");
          } else {
            toast.error("response.data.message");
            navigate("/Login");
          }
        } else {
          alert(response.data.message);
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast(err?.response?.data?.message);
        toast.success(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    toast(isError?.error?.data?.message);
  }, [isError]);
  return (
    <>
      {/* <MainHeader/> */}
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="signup_container shadow">
          <div className="image_con">
            <div className="motive_text overlay1">
              <h2>Manage your events perfectly</h2>
            </div>
          </div>
          <div className="form_con">
            <div className="action_form">
              <h2>Login</h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      className="E_mail"
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      {...register("email", { required: true })}
                      autoComplete="off"
                    />
                    {errors.email && (
                      <span className="custom-error">Email is required</span>
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Password"
                      name="password"
                      {...register("password", { required: true })}
                      autoComplete="off"
                    />
                    {errors.password && (
                      <span className="custom-error">
                        {errors.password?.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Link className="Forgot_P" to="/ForgotPassword">
                    {" "}
                    Forgot Password?
                  </Link>
                </Row>
                <Button className="btn button_mg" type="submit">
                  Login
                </Button>
                <div className="login_with_div">
                  <div className="more_login_text"> Or Login with</div>
                </div>
                <div className="Login_with_link" style={{ cursor: "pointer " }}>
                  <AppleLogin
                    clientId="app.easyevents.webapp"
                    scope="name email"
                    redirectURI="https://easyevents.app/SignUp"
                    //callback={appleResponse}
                    responseMode="query"
                  />
                </div>
                <div className="not_account">
                  Don’t have an account?{" "}
                  <Link to="/Signup">Create new Account</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;

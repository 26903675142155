import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const SuperAdminSlice = createApi({
  reducerPath: "SuperAdminSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    dashboardDetails: builder.query({
      query: () => ({
        url: "superAdmin/dashDetails",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    getEventDetails: builder.query({
      query: () => ({
        url: `superAdmin/getAllEvents`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const { useDashboardDetailsQuery, useGetEventDetailsQuery } =
  SuperAdminSlice;

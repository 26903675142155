import { configureStore } from "@reduxjs/toolkit";
import { AuthSlice } from "./Redux/Slices.js/AuthSlice";
import { PricingPlanSlice } from "./Redux/Slices.js/PricingPlanSlice";
import { EventSlice } from "./Redux/Slices.js/EventSlice";
import { TeamMemberSlice } from "./Redux/Slices.js/TeamMemberSlice";
import { SuperAdminSlice } from "./Redux/Slices.js/SuperAdminSlice";
import { BudgetSlice } from "./Redux/Slices.js/BudgetSlice";

export const store = configureStore({
  reducer: {
    // user: UserSlice,

    [AuthSlice.reducerPath]: AuthSlice.reducer,
    [PricingPlanSlice.reducerPath]: PricingPlanSlice.reducer,
    [EventSlice.reducerPath]: EventSlice.reducer,
    [TeamMemberSlice.reducerPath]: TeamMemberSlice.reducer,
    [SuperAdminSlice.reducerPath]: SuperAdminSlice.reducer,
    [BudgetSlice.reducerPath]: BudgetSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AuthSlice.middleware,
      PricingPlanSlice.middleware,
      EventSlice.middleware,
      BudgetSlice.middleware,
      TeamMemberSlice.middleware,
      SuperAdminSlice.middleware
    ),
});

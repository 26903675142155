import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-multiple-select-dropdown-lite/dist/index.css";
import * as _ from "lodash";
import get_url from "./common/ApiCaller";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import SideBar from "../events/SideBar";
import Table from "react-bootstrap/Table";
import EventDetails from "../events/EventDetails";
import $, { error } from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ThreeDots } from "react-loader-spinner";
import { Delete, Get, Post_Method } from "../Services/services.midlware";

const TeamManagement = (props) => {
  window.scrollTo(0, 0);
  const [usrList, setUsrList] = useState([]);
  const [showModal, setShow] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedUsr, setSelectedUsr] = useState({});
  const [modeOfAdd, setModeforAdd] = useState("normal");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditModalClose = () => setEditModal(false);
  const [eventData, setEventData] = useState({});
  const [isEnded, setIsEnded] = useState(false);
  const event_id = localStorage.getItem("event_id");
  const [showTeam, setShowTeam] = useState("");
  const [phone, setPhone] = useState(false);
  const [contactsDrop, setContactsDrop] = useState("");
  const [contactsType, setContactsType] = useState("");
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [teamUser, setTeamUser] = useState({});
  const [activePlan, setActivePlan] = useState({});

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const navigate = useNavigate();
  const eventTypeOf = localStorage.getItem("event_type");
  const userDetail = () => {
    axios
      .get(`${get_url.local_url}userRoute/get-user`, header)
      .then((response) => {
        const userData = response.data.data;
        if ("undefined" === userData) {
          navigate("/Login");
        }
        setUserDetails(userData);
      });
  };

  const handleContact = (e) => {
    setContectType(e.target.value);
  };

  const fetchNewTeamList = async () => {
    await Get(`teamMng/allTeamMembers`).then((resp) => {
      const get_team_data = resp.data.data;
      setUsrList(get_team_data);
      // setShowTeam(get_team_data)
      // setIsLoaderDisplay(false)
    });
  };

  const delete_team = async (team_id) => {
    setIsLoaderDisplay(true);
    try {
      // if (window.confirm("Are you sure to delete this team member.")) {
      const response = await Delete(
        `teamMng/removeMember/${selectedMember}/${header.headers.event_id}`
      );

      if (response.status === 200) {
        toast("Team deleted successfully.");
        fetchTeam();
        fetchNewTeamList();
        setShowDeleteModal(false);
      } else {
        // Handle non-200 HTTP status codes appropriately
        console.error(
          `Delete request failed with status code: ${response.status}`
        );
        // You can display an error message to the user if needed.
      }
      // } else {
      //   setIsLoaderDisplay(false);
      // }
    } catch (error) {
      // Handle other errors, e.g., network issues, timeouts, etc.
      toast("An error occurred while deleting the team:", error);
    }
  };
  function random_password() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";
    for (let i = 1; i <= 8; i++) {
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }
    return pass + "@Ee1";
  }
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [companyName, setCompanyName] = useState();
  const [contectType, setContectType] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const handleDeleteEvent = (row) => {
    setSelectedMember(row);
    setShowDeleteModal(true);
  };
  const addTeam = async () => {
    const nameRegex = /^[a-zA-Z\s]{1,50}$/; // Allows only letters and spaces, up to 50 characters
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;
    if ("" === firstName || !nameRegex.test(firstName)) {
      alert("Please enter a valid first name (up to 50 characters).");
      return false;
    }

    if ("" === lastName || !nameRegex.test(lastName)) {
      alert("Please enter a valid last name (up to 50 characters).");
      return false;
    }

    if ("" === email || !emailRegex.test(email)) {
      alert("Please enter a valid email.");
      return false;
    }

    if ("" === number) {
      alert("Please enter a valid phone number.");
      return false;
    }

    if ("" === companyName) {
      alert("Please enter your company name.");
      return false;
    }

    if ("" === contectType) {
      alert("Please select your contact type.");
      return false;
    }

    let team_data = {
      event_id: header.headers.event_id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: number,
      company_name: companyName,
      contact_type_id: contectType,
      start_date: eventData.start_date,
      end_date: eventData.end_date,
      // password :  random_password()
    };

    setIsLoaderDisplay(true);
    let url = `${get_url.local_url}teamMng/addTeamMember`;

    if (modeOfAdd == "existingMember") {
      url = `${get_url.local_url}teamMng/assignEvent`;
      team_data = {
        user_id: selectedUsr._id,
        event_id: header.headers.event_id,

        contact_type_id: contectType,
        start_date: eventData.start_date,
        end_date: eventData.end_date,
        // password :  random_password()
      };
    }
    await axios
      .post(url, team_data, header)
      .then((response) => {
        if (response.data.status == 409) {
          toast("Email already in use.");
          setIsLoaderDisplay(false);
          return false;
        }
        setSelectedUsr({});
        $("[aria-label=Close]").trigger({ type: "click" });
        $(".field_for_empty").val("");
        toast("Team added successfully.");
        fetchTeam();
      })
      .catch((error) => {
        $("[aria-label=Close]").trigger({ type: "click" });
        toast.error(error?.response?.data?.data?.message);
        setIsLoaderDisplay(false);
      });
  };

  const fetchTeam = async () => {
    setIsLoaderDisplay(true);
    await Get(`teamMng/get-team/${localStorage.getItem("event_id")}`).then(
      (resp) => {
        const get_team_data = resp.data.data;
        setShowTeam(get_team_data);
        setIsLoaderDisplay(false);
      }
    );
  };
  const contactType = async () => {
    await axios
      .get(`${get_url.local_url}contact/contactList/${header.headers.event_id}`)
      .then((response) => {
        const get_contact_type = response.data.data;
        setContactsType(get_contact_type);
      });
  };
  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id")
      ? localStorage.getItem("event_id")
      : 0;
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        setEventData(get_event_data);
      });
  };

  const getTeamMember = async (teamMemberId) => {
    if ("undefined" === typeof teamMemberId || "" === teamMemberId) {
      return false;
    }
    Get(
      `teamMng/getIndividual?event_id=${header.headers.event_id}&user_id=${teamMemberId}`
    ).then((response) => {
      if (200 != response.data.statusCode) {
        alert(response.data.message);
        return false;
      }
      setTeamUser(response.data.data);
      setEditModal(true);
    });
    // await axios.get(`${get_url.local_url}team-user/`+teamMemberId, header).
  };

  const updateTeamUser = async (teamMemberId) => {
    if ("undefined" === typeof teamMemberId || "" === teamMemberId) {
      return false;
    }

    if ("" === firstName) {
      alert("Please enter your first name.");
      return false;
    }
    if ("" === lastName) {
      alert("Please enter your last name.");
      return false;
    }
    if ("" === email) {
      alert("Please enter your email.");
      return false;
    }
    if ("" === number) {
      alert("Please enter your phone number.");
      return false;
    }
    if ("" === companyName) {
      alert("Please enter your company name.");
      return false;
    }
    if ("" === contectType) {
      alert("Please select your conatct type.");
      return false;
    }
    console.log(contectType, "contect");
    const team_data = {
      list_id: teamUser._id,
      event_id: header.headers.event_id,
      teamMemberId: teamMemberId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: number,
      company_name: companyName,
      contact_type_id: contectType,
    };
    await Post_Method("teamMng/updateMemberDetails", team_data)
      .then((resp) => {
        setEditModal(false);
        fetchTeam();
      })
      .catch((err) => {
        toast(err.response.data.message);
      });
  };

  const subscriptionDetail = async () => {
    await Get(`subscription/mySubscriptions`).then((resp) => {
      setActivePlan(resp.data.data);
    });
  };

  useEffect(() => {
    fetchEventData();
    fetchNewTeamList();
    userDetail();
    fetchTeam();
    contactType();
    subscriptionDetail();
  }, [isEnded]);
  return (
    <>
      {/* {isLoaderDisplay? <div className='overlay'><div className="center_dots"><ThreeDots height="80" width="80" radius="9" color="#0d6efd" /></div></div>:''} */}

      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row">
            <div className="col-lg-9 col-md-6">
              <div className="assign_outer">
                <h2>Team Management</h2>
                <p>Use this page to manage your team</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="accordion" id="accordionExample">
            {showTeam.length < activePlan?.team_member ? (
              <div className="Add_more_event">
                <ButtonToolbar aria-label="Toolbar with button groups">
                  {eventTypeOf == "close_event" ? null : (
                    <>
                      <Button
                        className="btn btn-primary clickme"
                        onClick={() => {
                          handleShow();
                          setModeforAdd("normal");
                        }}
                        onHide={handleClose}
                      >
                        Add New Member
                      </Button>
                      <Button
                        style={{ marginLeft: 10 }}
                        className="btn btn-primary clickme"
                        onClick={() => {
                          handleShow();
                          setModeforAdd("existingMember");
                        }}
                        onHide={() => {
                          handleClose();
                          setModeforAdd("normal");
                        }}
                        //  onHide={handleClose}
                      >
                        Add Existing Member
                      </Button>
                    </>
                  )}
                </ButtonToolbar>
              </div>
            ) : (
              ""
            )}
            <div className="custom-table-responsive mt-3">
              <Table
                className="table tableC team_management_table"
                responsive="sm md lg"
              >
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Contact Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={userDetails._id}>
                    <td></td>
                    <td>{userDetails.first_name}</td>
                    <td>{userDetails.phone_number}</td>
                    <td>{userDetails.email}</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  {showTeam &&
                    showTeam.length > 0 &&
                    showTeam?.map((item, index) => {
                      return (
                        <>
                          <tr key={item._id} id={`team_id_${item._id}`}>
                            <td>
                              <ul className="action_ul_2">
                                <li>
                                  <i
                                    className="fas fa-trash deleete"
                                    onClick={() => {
                                      handleDeleteEvent(item.user_id._id);
                                      // delete_team(item.user_id._id);
                                    }}
                                  ></i>
                                </li>
                                <li>
                                  <i
                                    class="fas fa-pencil-alt custom_ty66"
                                    onClick={() => {
                                      getTeamMember(item.user_id._id);
                                    }}
                                  ></i>
                                </li>
                              </ul>
                            </td>
                            <td>
                              {item?.user_id?.first_name}{" "}
                              {item?.user_id?.last_name}
                            </td>
                            <td>{item?.user_id?.phone_number}</td>
                            <td>{item?.user_id?.email}</td>
                            <td>{item?.user_id?.company_name}</td>
                            <td>
                              {item?.contact_type_id
                                ? item.contact_type_id.name
                                : "N/A"}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Team</Modal.Title>
        </Modal.Header>
        <Container>
          <Modal.Body scrollable>
            <Row>
              {modeOfAdd == "existingMember" && (
                <Col xs={12} md={12} className="bottom_space">
                  <Form.Group controlId="user_select">
                    <Form.Label>Select User</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setSelectedUsr(JSON.parse(e.target.value));
                      }}
                      className="select-option-arrow"
                      multiple={false}
                      name="contact_type_id"
                      autoComplete="off"
                    >
                      <option defaultValue="">selected</option>
                      {usrList &&
                        usrList.length > 0 &&
                        usrList.map((curr, index) => {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(curr) || ""}
                            >
                              {curr?.first_name + " " + curr.last_name}
                            </option>
                          );
                        })}
                      ;
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}

              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="first_name">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={modeOfAdd == "existingMember" ? true : false}
                    defaultValue={
                      modeOfAdd == "existingMember" && selectedUsr
                        ? selectedUsr?.first_name
                        : ""
                    }
                    name="first_name"
                    required
                    placeholder="Enter First Name"
                    className="field_for_empty"
                    autoComplete="off"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="last_name">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={modeOfAdd == "existingMember" ? true : false}
                    defaultValue={
                      modeOfAdd == "existingMember" && selectedUsr
                        ? selectedUsr?.last_name
                        : ""
                    }
                    name="last_name"
                    required
                    placeholder="Enter Last Name"
                    className="field_for_empty"
                    autoComplete="off"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled={modeOfAdd == "existingMember" ? true : false}
                    defaultValue={
                      modeOfAdd == "existingMember" && selectedUsr
                        ? selectedUsr?.email
                        : ""
                    }
                    type="email"
                    name="email"
                    required
                    placeholder="Enter Email"
                    className="field_for_empty"
                    autoComplete="off"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  {modeOfAdd == "existingMember" ? (
                    <Form.Control
                      disabled
                      defaultValue={
                        modeOfAdd == "existingMember" && selectedUsr
                          ? selectedUsr?.phone_number
                          : ""
                      }
                      type="email"
                      name="email"
                      required
                      placeholder="Enter Email"
                      className="field_for_empty"
                      autoComplete="off"
                    ></Form.Control>
                  ) : (
                    <PhoneInput
                      placeholder="Enter phone number"
                      inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: true,
                        id: "phone_number",
                      }}
                      onChange={(e) => {
                        setPhone(e);
                        setNumber(e);
                      }}
                      className="field_for_empty"
                      autoComplete="off"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="company_name">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    disabled={modeOfAdd == "existingMember" ? true : false}
                    defaultValue={
                      modeOfAdd == "existingMember" && selectedUsr
                        ? selectedUsr?.company_name
                        : ""
                    }
                    required
                    placeholder="Enter Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    className="field_for_empty"
                    autoComplete="off"
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="contact_type">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Select
                    className="select-option-arrow"
                    onChange={handleContact}
                    multiple={false}
                    name="contact_type_id"
                    value={contectType}
                    autoComplete="off"
                  >
                    <option>selected</option>
                    {contactsType &&
                      contactsType.map((contact, index) => {
                        return (
                          <option key={index} value={contact._id || ""}>
                            {contact.name}
                          </option>
                        );
                      })}
                    ;
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                addTeam();
              }}
            >
              {" "}
              Add to Team
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>

      {/* EDIT TEAM MODAL*/}

      <Modal
        show={showEditModal}
        onHide={handleEditModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Team
          </Modal.Title>
        </Modal.Header>
        <Container>
          <Modal.Body scrollable>
            <Row>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_first_name">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    required
                    placeholder="Enter First Name"
                    className="field_for_empty"
                    autoComplete="off"
                    defaultValue={teamUser?.user_id?.first_name}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_last_name">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                    placeholder="Enter Last Name"
                    className="field_for_empty"
                    autoComplete="off"
                    defaultValue={teamUser?.user_id?.last_name}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    placeholder="Enter Email"
                    className="field_for_empty"
                    autoComplete="off"
                    defaultValue={teamUser?.user_id?.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    inputProps={{
                      name: "phone_number",
                      required: true,
                      autoFocus: true,
                      id: "edit_phone_number",
                    }}
                    onChange={(e) => {
                      setPhone(e);
                      setNumber(e);
                    }}
                    className="field_for_empty"
                    autoComplete="off"
                    defaultValue={teamUser?.user_id?.phone_number}
                    value={teamUser?.user_id?.phone_number}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_company_name">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    required
                    placeholder="Enter Company Name"
                    className="field_for_empty"
                    autoComplete="off"
                    defaultValue={teamUser?.user_id?.company_name}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="bottom_space">
                <Form.Group controlId="edit_contact_type">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Select
                    className="select-option-arrow"
                    onChange={handleContact}
                    multiple={false}
                    name="contact_type_id"
                    autoComplete="off"
                    defaultValue={teamUser?.contact_type_id?._id}
                  >
                    <option defaultValue="" disabled>
                      selected
                    </option>
                    {contactsType &&
                      contactsType.map((contact, index) => {
                        return (
                          <option key={index} value={contact._id || ""}>
                            {contact.name}
                          </option>
                        );
                      })}
                    ;
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleEditModalClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                updateTeamUser(teamUser.user_id._id);
              }}
            >
              {" "}
              Update Team
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>

      {/* Delete memeber */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the member?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={delete_team}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TeamManagement;

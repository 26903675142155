import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import MainHeader from "../components/shared/MainHeader";
import Footer from "../components/shared/Footer";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import style from "../App/public/css/super.css";
import { Get, Post_Method } from "../Services/services.midlware";
import { toast } from "react-toastify";
import { useGetProfileQuery } from "../Redux/Slices.js/AuthSlice";
import {
  useGetPlansQuery,
  usePurchasePlanMutation,
} from "../Redux/Slices.js/PricingPlanSlice";
import Loader from "../Component/Loader";
const PricingPlans = (props) => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState([]);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const { data: getProfile, refetch } = useGetProfileQuery();
  const { data: getPlans, isLoading } = useGetPlansQuery();
  const [purchasePlan, isError] = usePurchasePlanMutation();
  useEffect(() => {
    toast(isError?.error?.data?.message);
  }, [isError]);

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(
        "pk_live_51LeJjzDd7PHj8FTXegXqLQcMyoje6tEzk0qYLfPpfIMHTE4MMpHl5rKAjjhiQ7iOOkBzwD9wSPcYMhVb6b1tnYFN00dNQyOHiQ"
      );
    }
    return stripePromise;
  };

  async function handleCheckout(price_id) {
    setIsLoaderDisplay(true);
    const data = {
      price_id: price_id,
    };
    purchasePlan(data)
      .then((response) => {
        setIsLoaderDisplay(false);
        if (400 === response.data.status) {
          toast(response.data.message);
          return false;
        }

        if (409 === response.data.status) {
          navigate("/Login");
        }
        window.location.href = response.data.data.url;
      })
      .catch((error) => {
        setIsLoaderDisplay(false);
        toast(error.response.data.message);
      })
      .finally(() => {
        setIsLoaderDisplay(false);
      });
    if (!localStorage.getItem("login_token")) {
      alert("Please log in to proceed with the plan purchase.");
      navigate("/Login");
    }
  }
  const getUserDetail = async () => {
    setUserDetail(getProfile?.data);
  };
  useEffect(() => {
    getUserDetail();
  });

  const getSubscriptionDetail = async () => {
    const get_subscription_detail = getPlans?.data;
    setSubscriptionDetail(get_subscription_detail);
  };

  useEffect(() => {
    getSubscriptionDetail();
  });

  return (
    <>
      {isLoaderDisplay || isLoading == true ? <Loader /> : ""}

      <MainHeader />
      <section className="Pricing_Banner">
        <div className="Pricing_B_text">
          <div> </div>
          <p className="head_Text">Pricing Plans</p>
          <p className="Tagline">Event Management is easy now!</p>
        </div>
      </section>
      <section className="">
        <div className="pricing_card_outer row">
          {subscriptionDetail?.length > 0
            ? subscriptionDetail?.map((sub_data, sub_key) => {
                return (
                  <>
                    <div className="Pricing_card col-lg-4 col-md-6 col-sm-12 shadow-lg">
                      <div className="Pricing_C_top">
                        <p className="Tier">Tier {sub_key + 1}</p>
                        <hr className="Divider" />
                        <p className="Plan_Name">{sub_data.plan_name}</p>
                        <p className="price">
                          {" "}
                          $ {sub_data.plan_amount}
                          <span className="post_bottom">/month</span>
                        </p>

                        <Button
                          variant="outline-primary Buy_btn"
                          onClick={() => {
                            handleCheckout(sub_data?.stripe_amount_id);
                          }}
                        >
                          Get Started
                        </Button>
                      </div>
                      <hr></hr>
                      <div className="pricing_features">
                        <ul className="Pricng_point">
                          <li className="picing_item">
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              {sub_data.create_events == -1
                                ? "Unlimited"
                                : sub_data.create_events}{" "}
                              Events at the Same Time{" "}
                            </p>
                          </li>

                          <li className="picing_item">
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              {sub_data.team_member == -1
                                ? "Unlimited"
                                : sub_data.team_member}{" "}
                              Team Members{" "}
                            </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.role_based_access == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              {" "}
                              Role Based Access for Team Members
                            </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.automated_checklist == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              Automated Checklist Creation{" "}
                            </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.budget == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">Budgeting Tools </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.checklist_reminder == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              Time-Relevant Checklist Reminders{" "}
                            </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.is_crm == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">Contacts/CRM </p>
                          </li>

                          <li
                            className={`picing_item ${
                              sub_data.chat_support == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">
                              Email Support + Call Support{" "}
                            </p>
                          </li>
                          <li
                            className={`picing_item ${
                              sub_data.training == 0 ? "d-none" : ""
                            }`}
                          >
                            <i className="fas fa-check"></i>
                            <p className="F_point">3 Free Live Trainings</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </div>
      </section>

      <Footer />
    </>
  );
};
export default PricingPlans;

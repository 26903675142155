import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
const countryStateCity = require("countrycitystatejson");

const Step5 = (props) => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countrylist,setCountryList] = useState([])
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const submitForm = () => {
    if (!selectedCountry) {
      toast("Please select the country.");
      return;
    }

    if (!selectedState) {
      toast("Please select the state.");
      return;
    }

    let event_data = {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      event_id: localStorage.getItem("event_id"),
    };

    axios
      .post(`${get_url.local_url}events/createEvent`, event_data, header)
      .then((response) => {
        navigate("/step6");
      });
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    if (event_id === null) {
      navigate("/PricingPlans");
    }
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        if (typeof get_event_data === "undefined") {
          navigate("/Dashboard");
        }
        setEventData(get_event_data);
        if (typeof get_event_data.city !== "undefined") {
          setSelectedCity(get_event_data.city);
        }
        if (typeof get_event_data.state !== "undefined") {
          setSelectedState(get_event_data.state);
        }
        if (typeof get_event_data.country !== "undefined") {
          setSelectedCountry(get_event_data.country);
        }
      });
  };

  useEffect(() => {
    createCountryArray()
    fetchEventData();
  }, []);

  let countries = countryStateCity.getAll();
  let country = []
  const createCountryArray = () =>{
  Object.keys(countries).map((key,ind) => {
    country.push({"key":key,name:countries[key].name,id:ind})
    setCountryList(country)
  })
  }

  const getStates = (countryName) => {
    setSelectedCountry(countryName);
    const countryStates = countryStateCity.getStatesByShort(countryName);
    setStates(countryStates);
    setCities([]);
  };

  const getCities = (stateName) => {
    setSelectedState(stateName);
    const countryName = selectedCountry;
    const stateCities = countryStateCity.getCities(countryName, stateName);
    setCities(stateCities);
  };

  const handleOnSelect = (item) =>{
    getStates(item.key)
  }

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 5 of 7</div>
      </div>
      <section className="planing_outer">
        <div className="container">
          <Link to="/step4">
            {" "}
            <i
              style={{ fontSize: "50px" }}
              class="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>Where is your Event ?</h4>
          </div>
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                <div style={{ width: 400 }}>
                        
                       </div>
                  <div className="input_outer with_text">
                    <label>Country</label>
                          <ReactSearchAutocomplete
                          className="searchDivCountry"
                            items={countrylist}
                            maxResults={8}
                            // onSearch={handleOnSearch}
                            // onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            // onFocus={handleOnFocus}
                            // autoFocus
                            // formatResult={formatResult}
                          />
                    {/* <DropdownButton
                      id="country"
                      title={selectedCountry || "Select Country"}
                      className="button_text_more"
                    >
                      {Object.keys(countries).map((key) => (
                        <Dropdown.Item onClick={() => getStates(key)} key={key}>
                          {countries[key].name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="input_outer with_text no_t5 m_top">
                    <label>State</label>
                    <DropdownButton
                      id="state"
                      title={selectedState || "Select State"}
                      className="button_text_more"
                    >
                      {states.map((state) => (
                        <Dropdown.Item
                          onClick={() => getCities(state)}
                          key={state}
                        >
                          {state}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="input_outer with_text location_field">
                    {cities.length != 0 ? (
                      <>
                        <label>City</label>
                        <DropdownButton
                          id="city"
                          title={selectedCity || "Select City"}
                          className="button_text_more"
                        >
                          {cities.map((city) => (
                            <Dropdown.Item
                              onClick={() => setSelectedCity(city)}
                              key={city}
                            >
                              {city}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_outer no_edit later3">
              <p className="p_text">You can always edit it later</p>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={submitForm}
              >
                {" "}
                Continue{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step5;

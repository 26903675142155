import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const PricingPlanSlice = createApi({
  reducerPath: "PricingPlanSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => ({
        url: "userRoute/subscriptionsList",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    purchasePlan: builder.mutation({
      query: (data) => ({
        url: `subscription/checkout`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
        },

        body: data,
      }),
    }),
  }),
});

export const {
  useGetPlansQuery,
  usePaymentSuccessQuery,
  usePurchasePlanMutation,
  usePay,
} = PricingPlanSlice;

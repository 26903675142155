import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventDetails from "./EventDetails";
import SideBar from "./SideBar";
import "./Dashboard.css";
import axios from "axios";
import get_url from "./common/ApiCaller";
import { ThreeDots } from "react-loader-spinner";
import { Get, Post_Method } from "../Services/services.midlware";
import {
  useGetTeamMemberUpcomingUserEventsQuery,
  useGetTeamMemberongoingUserEventsQuery,
  useGetTeamMemberpastUserEventsQuery,
} from "../Redux/Slices.js/TeamMemberSlice";
import Loader from "../Component/Loader";

const DashboardUsers = () => {
  const navigate = useNavigate();
  const { event_id } = useParams();
  const [userDetail, setUserDetail] = useState([]);

  // const [ongoingEvent, setOngoingEvent] = useState([]);

  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [eventData, setEventData] = useState({});
  const { data: getTeamMemberUpcomingUserEvents } =
    useGetTeamMemberUpcomingUserEventsQuery();
  const { data: getTeamMemberongoingUserEvents } =
    useGetTeamMemberongoingUserEventsQuery();
  const { data: getTeamMemberpastUserEvents } =
    useGetTeamMemberpastUserEventsQuery();

  const ongoingEvent = getTeamMemberongoingUserEvents?.data;
  const closeEvent = getTeamMemberpastUserEvents?.data;
  const upcomingEvent = getTeamMemberUpcomingUserEvents?.data;
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const changeEvent = (event_id, check_list_id, type_of_event) => {
    localStorage.setItem("event_id", event_id);
    localStorage.setItem("selected_category", JSON.stringify(check_list_id));
    localStorage.setItem("type_of_event", type_of_event);
    // window.location.reload();
    window.open(`Dashboard/${event_id}`, "_blank");
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id")
      ? localStorage.getItem("event_id")
      : 0;
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        setEventData(get_event_data);
      });
  };

  useEffect(() => {
    if (event_id != undefined) {
      fetchEventData();
    }
  }, []);

  const getUserDetail = async () => {
    await axios
      .get(`${get_url.local_url}userRoute/get-user`, header)
      .then((response) => {
        const get_user_detail = response.data.data;
        if ("undefined" === typeof get_user_detail || null == get_user_detail) {
          navigate("/Login");
        }
        setUserDetail(get_user_detail);
        setIsLoaderDisplay(false);
      });
  };

  const createEvent = () => {
    localStorage.removeItem("event_id");
    localStorage.removeItem("selected_category");
    navigate("/step1");
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  return (
    <>
      {isLoaderDisplay ? <Loader /> : ""}
      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section class="check_list">
        <SideBar />
        <div className="check_right">
          <div class="row">
            <main class="col-md-12 col-md-6">
              <h3 className="Welcome_text">
                Welcome {userDetail.first_name} {userDetail.last_name}
              </h3>
              {event_id != undefined ? (
                <div className="event_card card one_event">
                  <p className="event_categ_ory">
                    {" "}
                    {eventData?.type_of_event == 1 ? "Business" : "Personal"}
                  </p>
                  <h5>{eventData?.type_of_event?.name}</h5>
                  <p className="Event_owner">{eventData?.event_name}</p>
                  <div className="Eve_status Eve_open mouse_click"> View</div>
                </div>
              ) : (
                <div>
                  <div className="events_list Page_box">
                    <div className="event_list_head justify-content-between mb-3 p-2">
                      <div className="eve_list_heading">
                        <h5>Upcoming Events</h5>
                      </div>
                      {/* <hr class="dropdown-divider" /> */}
                      <div className="see_all">
                        <div>
                          {/* <div className={`btn btn-primary ${(userRole === 'user')?'d-none':'' }`} onClick={ ()=>{ createEvent() } }>Create Event</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="events_row">
                      {upcomingEvent?.map((upcoming_event_data, k) => {
                        return (
                          <div className="event_card card">
                            <p className="event_categ_ory">
                              {" "}
                              {upcoming_event_data?.type_of_event == 1
                                ? "Business"
                                : "Personal"}
                            </p>
                            <h5>{upcoming_event_data?.event_types?.name}</h5>
                            <p className="Event_owner">
                              {" "}
                              {
                                upcoming_event_data?.event_id[0]?.event_name
                              }{" "}
                            </p>
                            {/* <p className='Eve_Discri'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>  */}
                            <div
                              className="Eve_status Eve_open mouse_click"
                              onClick={() => {
                                changeEvent(
                                  upcoming_event_data?._id,
                                  upcoming_event_data?.event_id[0]
                                    ?.checklist_id,
                                  "upcoming_event"
                                );
                              }}
                            >
                              {" "}
                              View
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="events_list Page_box">
                      <div className="event_list_head mb-3 p-2">
                        <div className="eve_list_heading">
                          <h5> Past Events</h5>
                        </div>
                        <div className="see_all">
                          <div>
                            {" "}
                            {/*
                        <Link to="#" variant="primary">See All</Link> */}
                          </div>
                        </div>
                      </div>
                      <div className="events_row">
                        {closeEvent?.map((close_event_data, k) => {
                          return (
                            <div className="event_card card ">
                              <p className="event_categ_ory">
                                {" "}
                                {close_event_data?.type_of_event == 1
                                  ? "Business"
                                  : "Personal"}
                              </p>
                              <h5>{close_event_data?.event_types?.name}</h5>
                              <p className="Event_owner">
                                {" "}
                                {close_event_data?.event_id[0]?.event_name}{" "}
                              </p>

                              <div
                                className="Eve_status Eve_closed mouse_click"
                                onClick={() => {
                                  changeEvent(
                                    close_event_data?._id,
                                    close_event_data?.event_id[0]?.checklist_id,
                                    "close_event"
                                  );
                                }}
                              >
                                {" "}
                                View
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="events_list Page_box">
                    <div className="event_list_head mb-3 p-2">
                      <div className="eve_list_heading">
                        <h5> Active Events</h5>
                      </div>
                      <div className="see_all">
                        <div>
                          {" "}
                          {/*
                      <Link to="#" variant="primary">See All</Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="events_row">
                      {ongoingEvent?.map((ongoing_event_data, k) => {
                        return (
                          <div className="event_card card">
                            <p className="event_categ_ory">
                              {" "}
                              {ongoing_event_data?.type_of_event == 1
                                ? "Business"
                                : "Personal"}
                            </p>
                            <h5>{ongoing_event_data?.event_types?.name}</h5>
                            <p className="Event_owner">
                              {" "}
                              {ongoing_event_data?.event_id[0]?.event_name}{" "}
                            </p>
                            <div
                              className="Eve_status Eve_closed mouse_click"
                              onClick={() => {
                                changeEvent(
                                  ongoing_event_data?.event_id[0]?._id,
                                  ongoing_event_data?.event_id[0]?.checklist_id,
                                  "ongoing_event"
                                );
                              }}
                            >
                              {" "}
                              View
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </section>
    </>
  );
};
export default DashboardUsers;

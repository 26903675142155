import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Get } from "../Services/services.midlware";
import { toast } from "react-toastify";
const PaymentFailed = () => {
  const navigate = useNavigate();
  let hasErrors = true;

  useEffect(() => {
    if (hasErrors === true) {
      userDetail();
      failedSubscription();
    }
  }, [hasErrors]);

  const userDetail = async () => {
    hasErrors = false;
    await Get("userRoute/get-user").then((response) => {
      const userData = response.data.data;
      if ("undefined" === userData) {
        navigate("/Login");
      }
    });
  };

  const failedSubscription = async () => {
    hasErrors = false;
    await Get("subscription/subscriptionFailed").then((response) => {
      if (response.data.status != 200) {
        toast(response.data.message);
        navigate("/Dashboard");
      }
      toast(response.data.message);
      navigate("/PricingPlans");
    });
  };
};
export default PaymentFailed;

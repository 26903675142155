import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "reactstrap";
import { Post_Method } from "../Services/services.midlware";

const Step4 = (props) => {
  const navigate = useNavigate();
  const [startDates, setStartDates] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const sDate = moment(startDates)
    .utcOffset(-300)
    .format("YYYY-MM-DD HH:mm:ss");
  const eDate = moment(endDate).utcOffset(-300).format("YYYY-MM-DD HH:mm:ss");

  const data1 = {
    start_date: sDate,
    end_date: endDate !== undefined ? eDate : "No date Selected",
  };

  const submitForm = () => {
    let get_start_dates = startDates;
    let get_end_date = endDate;
    if (get_start_dates == undefined || get_start_dates == "") {
      toast("Please select start date.");
    } else if (get_end_date == undefined || get_end_date == "") {
      toast("Please select end date.");
    } else if (get_start_dates > get_end_date) {
      toast("End date should be greater than start date.");
    } else {
      let event_data = {
        event_id: localStorage.getItem("event_id"),
        start_date: get_start_dates,
        end_date: get_end_date,
      };

      axios
        .post(`${get_url.local_url}events/createEvent`, event_data, header)
        .then((response) => {
          navigate("/step5");
        });
    }
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");

    if (null == event_id) {
      navigate("/PricingPlans");
    }

    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        if ("undefined" === typeof get_event_data) {
          navigate("/Dashboard");
        }

        if (
          get_event_data.start_date != undefined &&
          get_event_data.start_date != undefined
        ) {
          setStartDates(new Date(get_event_data.start_date));
          setEndDate(new Date(get_event_data.end_date));
        }
      });
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 4 of 7</div>
      </div>
      <section className="planing_outer">
        <div className="container">
          <Link to="/step3">
            {" "}
            <i
              style={{ fontSize: "50px" }}
              className="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>When is your event?</h4>
          </div>
          <div className="row top_ma56 custom_date12">
            <div className="col-lg-6">
              <div className="input_outer date45">
                <Label>Start Date and Time</Label>
                <DatePicker
                  placeholderText="MM/DD/YYYY hh:mm aa"
                  selected={startDates}
                  onChange={(date) => {
                    var check = moment(date).isBefore(new Date());
                    if (check) {
                      setStartDates(new Date());
                    } else {
                      setStartDates(date ? date : "");
                    }
                  }}
                  showTimeSelect
                  minDate={new Date()} // set the current date to disable past date
                  dateFormat="MM/dd/yyyy  hh:mm aa"
                  required={true}
                  name="start_date"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_outer date45 bot">
                <Label>End Date and Time</Label>
                <DatePicker
                  selected={endDate}
                  placeholderText="MM/DD/YYYY hh:mm aa"
                  onChange={(date) => {
                    var check = moment(date).isBefore(new Date());
                    if (check) {
                      setEndDate(new Date());
                    } else {
                      setEndDate(date);
                    }
                  }}
                  selectsEnd
                  showTimeSelect
                  disabled={startDates === null ? true : false}
                  minDate={startDates} // set the current date to disable past date
                  minTime={"00:00"} // set current time to disable past time
                  maxTime={setHours(setMinutes(new Date(), 59), 23)} // this sets the max time (i.e. 23:59)
                  dateFormat="MM/dd/yyyy  hh:mm aa"
                  name="end_date"
                  autoComplete="off"
                  id="end_datess"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="btn_outer no_edit later3">
            <p className="p_text">You can always edit it later</p>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                submitForm();
              }}
            >
              {" "}
              Continue{" "}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step4;

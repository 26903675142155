import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const BudgetSlice = createApi({
  reducerPath: "BudgetSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    deleteBudget: builder.mutation({
      query: (data) => ({
        url: `change-password`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
        },

        body: data,
      }),
    }),
    getBudgets: builder.query({
      query: () => ({
        url: `bugets/getBudgetList/${localStorage.getItem("event_id")}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    getEventTaskList: builder.query({
      query: () => ({
        url: `getEventTaskList/${localStorage.getItem("event_id")}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    categoriesList: builder.query({
      query: () => ({
        url: `catSubCat/categoriesList`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const {
  useGetBudgetsQuery,
  useCategoriesListQuery,
  useGetEventTaskListQuery,
} = BudgetSlice;

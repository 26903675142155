import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const TeamMemberSlice = createApi({
  reducerPath: "TeamMemberSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    getTeamMemberUpcomingUserEvents: builder.query({
      query: () => ({
        url: "events/upcomingUserEvents",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    getTeamMemberpastUserEvents: builder.query({
      query: () => ({
        url: "events/pastUserEvents",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    getTeamMemberongoingUserEvents: builder.query({
      query: () => ({
        url: "events/ongoingUserEvents",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const {
  useGetTeamMemberUpcomingUserEventsQuery,
  useGetTeamMemberongoingUserEventsQuery,
  useGetTeamMemberpastUserEventsQuery,
} = TeamMemberSlice;

import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { useLoginMutation } from "../Redux/Slices.js/AuthSlice";

const Login = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("email name is required"),
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const data1 = {
      email: data.email,
      password: data.password,
      //  role:"superAdmin"
    };

    login(data1)
      .then((response) => {
        toast(response?.error?.data?.message);

        if (response.data.statusCode == "200") {
          localStorage.setItem("login_token", response.data.token);

          localStorage.setItem("tokens", response.data.token);
          localStorage.setItem("role", response.data.data.role);
          // localStorage.setItem('user_id', JSON.stringify(response.data.data._id));
          if (response.data.data.role == "superAdmin") {
            // alert("Super Admin Login Successfully !");
            navigate("/Dashboard1");
          } else {
            toast(response.data.message);
            navigate("/Login");
          }
        } else {
          console.log("lkdskjk");
          toast(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message) toast(err.response.data.message);
        else alert("Server/connection error please try again latter.");
      });
  };
  return (
    <>
      {/* <MainHeader/> */}
      <div>
        <div className="signup_container shadow">
          <div className="image_con">
            <div className="motive_text overlay1">
              <h2>Manage your events perfectly</h2>
            </div>
          </div>
          <div className="form_con">
            <div className="action_form">
              <h2>Login</h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      className="E_mail"
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      {...register("email", { required: true })}
                      autoComplete="off"
                    />{" "}
                    {errors.email && (
                      <span className="custom-error">Email is required</span>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Password"
                      name="password"
                      {...register("password", { required: true })}
                      autoComplete="off"
                    />{" "}
                    {errors.password && (
                      <span className="custom-error">
                        {errors.password?.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Link className="Forgot_P" to="/ForgotPassword">
                    {" "}
                    Forgot Password?
                  </Link>
                </Row>
                <Button className="btn button_mg" type="submit">
                  {" "}
                  Login{" "}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/>  */}
    </>
  );
};
export default Login;

import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import EventDetails from "./EventDetails";
import axios from "axios";
import get_url from "./common/ApiCaller";
import { toast } from "react-toastify";
import { Delete, Get } from "../Services/services.midlware";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

export default function Check() {
  const [categoryData, setCategoryData] = useState([]);
  const [userData, setUserData] = useState([]);

  const eventTypeOf = localStorage.getItem("event_type");
  const [isHidden, setIsHidden] = useState(true);
  const [todayDate, setTodayDate] = useState();
  const [taskName, setTaskName] = useState();
  const [userId, setUserId] = useState();
  const [cid, setCid] = useState("");
  const [subCid, setSubCid] = useState("");
  const [tId, setTid] = useState("");
  const [del, seldel] = useState();
  const [showModal, setShowModal] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [isPreDefine, setIsPreDefine] = useState(true);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setTaskIdToDelete(null);
    setIsPreDefine(true); // Reset isPreDefine when modal is closed
  };

  const handleShow = (taskId, isPreDefine) => {
    setTaskIdToDelete(taskId);
    setIsPreDefine(typeof isPreDefine === "undefined" ? true : isPreDefine);
    setShowModal(true);
  };
  const delete_add_more = () => {
    setIsHidden(true);
  };
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };
  const add_new_task = (
    category_id,
    sub_category_id,
    task_id,
    get_user_data
  ) => {
    setCid(category_id);
    setSubCid(sub_category_id);
    setTid(task_id);

    setIsHidden((prevIsHidden) => !prevIsHidden);
  };
  useEffect(() => {
    let event_id = localStorage.getItem("event_id");

    axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const event_data = response.data[0];
        let selected_checklist = JSON.stringify(event_data.checklist_id);
        if (localStorage.getItem("selected_category") == null) {
          localStorage.setItem(
            "selected_category",
            JSON.stringify(selected_checklist)
          );
        }
      })
      .catch((error) => console.log(error?.message));
    let selected_category_id = localStorage.getItem("selected_category");
    let send_data = {
      event_id: localStorage.getItem("event_id"),
      selected_category_id: selected_category_id,
    };
    axios
      .post(`${get_url.local_url}events/check-list-data`, send_data, header)
      .then((response) => {
        const all_category_data = response.data.data;
        setCategoryData(all_category_data);
      })
      .catch((error) => console.log(error?.message));
    Get(`teamMng/get-team/${header.headers.event_id}`)
      .then((response) => {
        const all_user_data = response.data.data;
        setUserData(all_user_data);
      })
      .catch((error) => toast(error?.message));
    axios.get(`${get_url.local_url}userRoute/get-user`, header);
  }, [del]);

  const add_checklist = async () => {
    // setIsLoaderDisplay(true)
    var a_checklist_data = [];
    var a_user_id_data = [];
    var a_due_date_data = [];

    document.querySelectorAll(".user_id").forEach((element, k) => {
      a_user_id_data[k] = element.value;
    });

    document.querySelectorAll(".due_date").forEach((element, k) => {
      a_due_date_data[k] = element.value;
    });

    document.querySelectorAll(".task_name").forEach((element, k) => {
      let taskNameValue = element.value.trim();
      if (taskNameValue !== "") {
        let get_name_data = element.name.split("@")[1];
        let category_id = get_name_data.split("|")[0];
        let sub_category_id = get_name_data.split("|")[1];
        let task_id = get_name_data.split("|")[2];
        let operation = get_name_data.split("|")[3];
        let is_pre_defined = get_name_data.split("|")[4];

        a_checklist_data[k] =
          category_id +
          "|" +
          sub_category_id +
          "|" +
          task_id +
          "|" +
          operation +
          "|" +
          is_pre_defined +
          "|" +
          element.value;
      }
    });

    let checklist_data = {
      get_checklist_data: a_checklist_data,
      get_user_id_data: a_user_id_data,
      get_due_date_data: a_due_date_data,
    };

    try {
      const response = await axios.post(
        `${get_url.local_url}events/addTaskTocheckList`,
        checklist_data,
        header
      );
      seldel(response);
      document.querySelectorAll(".new_added_task").forEach((element) => {
        element.style.display = "none";
      });

      //fetch_static_category_data()
      toast("Task added successfully.");
      //setIsLoaderDisplay(false)
    } catch (error) {
      toast(error?.message);
    }
  };
  const changeTaskName = (task_val, task_id) => {
    document.getElementById(`task_input_${task_id}`).value = task_val;
  };
  const delete_task = async (task_id, is_pre_define) => {
    try {
      await Delete(`events/removeChecklistTask/${taskIdToDelete}`);
      seldel(task_id);
      toast("Task deleted successfully.");
    } catch (error) {
      toast(error?.message);
    } finally {
      handleClose(); // Close the modal regardless of success or error
    }
  };
  // setIsLoaderDisplay(true);
  //   if (window.confirm("Are you sure to delete this task.")) {
  //     is_pre_define =
  //       "undefined" === typeof is_pre_define ? true : is_pre_define;
  //     await Delete(`events/removeChecklistTask/${task_id}`)
  //       // await axios
  //       //   .delete(
  //       //     `${get_url.local_url}delete-task/${task_id}/${is_pre_define}`,
  //       //     header
  //       //   )
  //       .then((response) => {
  //         // fetch_static_category_data();
  //         seldel(response);
  //         toast("Task deleted successfully.");
  //       })
  //       .catch((error) => toast(error?.message));
  //   } else {
  //     // setIsLoaderDisplay(false);
  //   }
  // };

  return (
    <>
      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <div className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row justify-content-between">
            <div className="col-lg-10 col-md-6">
              <div className="assign_outer">
                <h2>Checklist</h2>
                <p>
                  Use this page to create, assign & keep track of checklist
                  items
                </p>
              </div>
            </div>
            <div className="col-lg-auto col-md-6">
              {eventTypeOf == "close_event" ? null : (
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    add_checklist();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          <hr />
          {categoryData.length > 0 &&
            categoryData.map((category_data, category_key) => {
              return (
                <>
                  <div className="category_label">
                    <h2 className="category_label_text">
                      {category_data?.name}
                    </h2>
                  </div>
                  {category_data.sub_category_data.length > 0 &&
                    category_data.sub_category_data.map(
                      (sub_category_data, sub_category_key) => {
                        return (
                          <>
                            <div
                              className="accordion category_accordian"
                              id="sa"
                            >
                              <div className={`item delitem`}>
                                <div className="item-header">
                                  <h2 className="mb-0">
                                    <div className="btn btn-link checklist_head">
                                      <div className="title_head_outer1">
                                        <span className="title_heading">
                                          {sub_category_data.name}
                                        </span>
                                      </div>
                                      <div className="right_options">
                                        <div className="right90 mt-0">
                                          {eventTypeOf ==
                                          "close_event" ? null : (
                                            <Link
                                              to="#"
                                              onClick={() => {
                                                add_new_task(
                                                  category_data._id,
                                                  sub_category_data._id,
                                                  "000000000000000000000000",
                                                  userData
                                                );
                                              }}
                                            >
                                              Add new task
                                            </Link>
                                          )}
                                        </div>
                                        <div>
                                          <button
                                            className={
                                              0 != sub_category_key
                                                ? "btn btn-link checklist_head "
                                                : "btn btn-link checklist_head collapsed"
                                            }
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#show_hide_func_${sub_category_data._id}`}
                                            aria-expanded={
                                              0 == sub_category_key
                                                ? "true"
                                                : "false"
                                            }
                                            aria-controls={"ar"}
                                          >
                                            <i className="fa fa-angle-down down-btn"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                                <div
                                  id={`show_hide_func_${sub_category_data?._id}`}
                                  className={`collapse ${
                                    subCategoryOpen ? "show" : ""
                                  }`}
                                  aria-labelledby={"head"}
                                  data-parent={"#accor"}
                                >
                                  {/* <div
                                  id={`show_hide_func_${sub_category_data?._id}`}
                                  className={`collapse ${
                                    sub_category_key == 0 ? "show" : ""
                                  }`}
                                  aria-labelledby={"head"}
                                  data-parent={"#accor"}
                                > */}
                                  <div className="t-p">
                                    <div className="table-responsive">
                                      <input
                                        type="hidden"
                                        name="checklist"
                                        // ref={exampleInput}
                                      />
                                      <table className="table custom_table">
                                        <thead>
                                          <tr>
                                            <th>&nbsp;</th>
                                            {/* <th>Done</th> */}
                                            <th>Task</th>
                                            <th>Assign To</th>
                                            <th>Due Date</th>
                                          </tr>
                                        </thead>
                                        <tbody
                                        //   id={`add_task_${sub_category_data?._id}`}
                                        >
                                          {isHidden == false ? (
                                            <tr
                                              className={`deltask new_added_task `}
                                            >
                                              <td
                                                className="custom_ty6"
                                                onClick={delete_add_more}
                                              >
                                                <img
                                                  className="custom_ty66"
                                                  src="../Assets/img/delete.png"
                                                  alt="Delete"
                                                />
                                              </td>
                                              <td className="custom_labe8">
                                                <input
                                                  type="text"
                                                  id={"name"}
                                                  placeholder="Task name"
                                                  className="task_name"
                                                  onChange={(e) => {
                                                    setTaskName(e.target.value);
                                                  }}
                                                  name={`task_name[@${cid}|${subCid}|${tId}|insert|false@]`}
                                                />
                                                <img
                                                  src="../Assets/img/edit.png"
                                                  alt="Edit"
                                                />
                                              </td>
                                              <td className="custom_DATE_PICKER">
                                                <div className="cutom_plus">
                                                  <img
                                                    src="../Assets/img/plus.png"
                                                    alt="Plus"
                                                  />
                                                  {/* <select
                                                    className="task-option user_id"
                                                    id={"user"}
                                                  >
                                                    <option value="">
                                                      Choose a user
                                                    </option>
                                                    {multy_option.map(
                                                    (option, index) => (
                                                      <option
                                                        key={index}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                                  </select> */}
                                                  <select
                                                    disabled={
                                                      eventTypeOf ==
                                                      "close_event"
                                                        ? true
                                                        : false
                                                    }
                                                    value={userId}
                                                    className="task-option user_id"
                                                    id={"user"}
                                                    onChange={(e) =>
                                                      setUserId(e.target.value)
                                                    }
                                                  >
                                                    <option value="">
                                                      Choose a users
                                                    </option>
                                                    {userData.length > 0 &&
                                                      userData.map(
                                                        (
                                                          user_data,
                                                          user_key
                                                        ) => {
                                                          return (
                                                            <option
                                                              value={
                                                                user_data._id
                                                              }
                                                            >
                                                              {
                                                                user_data
                                                                  .user_id
                                                                  ?.first_name
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                              </td>
                                              <td className="custom_task_name">
                                                <div className="cutom_plus">
                                                  <img
                                                    src="../Assets/img/plus.png"
                                                    alt="Plus"
                                                  />
                                                  <input
                                                    type="date"
                                                    min={todayDate}
                                                    value={todayDate}
                                                    onChange={(e) =>
                                                      setTodayDate(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="date"
                                                    className="due_date"
                                                    style={{ border: "none" }}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          ) : null}

                                          {sub_category_data.task_data?.length >
                                            0 &&
                                            sub_category_data.task_data.map(
                                              (task_data, task_key) => {
                                                return (
                                                  <tr
                                                    className="deltask"
                                                    id={`task_id_${task_data._id}`}
                                                  >
                                                    <td
                                                      className="custom_ty6"
                                                      onClick={() => {
                                                        handleShow(
                                                          task_data._id,
                                                          task_data.pre_define
                                                        );
                                                        // delete_task(
                                                        //   task_data._id,
                                                        //   task_data.pre_define
                                                        // );
                                                      }}
                                                    >
                                                      <img
                                                        className="custom_ty66"
                                                        src="../Assets/img/delete.png"
                                                      />
                                                    </td>
                                                    <td className="custom_labe8">
                                                      <input
                                                        disabled={
                                                          eventTypeOf ==
                                                          "close_event"
                                                            ? true
                                                            : false
                                                        }
                                                        type="text"
                                                        placeholder="Task name"
                                                        className="task_name utm_custom_value"
                                                        name={`task_name[@${
                                                          category_data._id
                                                        }|${
                                                          sub_category_data._id
                                                        }|${task_data._id}|${
                                                          task_data.pre_define
                                                            ? "insert"
                                                            : "update"
                                                        }|${
                                                          task_data.pre_define
                                                        }@]`}
                                                        defaultValue={
                                                          task_data.name
                                                        }
                                                        id={`task_input_${task_data._id}`}
                                                        onChange={(e) => {
                                                          changeTaskName(
                                                            e.target.value,
                                                            task_data._id
                                                          );
                                                        }}
                                                      />
                                                      <img src="../Assets/img/edit.png" />
                                                    </td>
                                                    <td className="custom_DATE_PICKER">
                                                      <div
                                                        className="cutom_plus"
                                                        // onClick={() =>
                                                        //   setUserListing(true)
                                                        // }
                                                      >
                                                        <img src="../Assets/img/plus.png" />
                                                        <select
                                                          disabled={
                                                            eventTypeOf ==
                                                            "close_event"
                                                              ? true
                                                              : false
                                                          }
                                                          className="task-option user_id"
                                                          id={"user"}
                                                        >
                                                          <option value="">
                                                            Choose a users
                                                          </option>
                                                          {userData.length >
                                                            0 &&
                                                            userData.map(
                                                              (
                                                                user_data,
                                                                user_key
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    value={
                                                                      user_data._id
                                                                    }
                                                                    selected={
                                                                      user_data._id ==
                                                                      task_data.user_assign_id
                                                                        ? "selected"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    {
                                                                      user_data
                                                                        .user_id
                                                                        ?.first_name
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                      </div>
                                                    </td>
                                                    <td className="custom_task_name">
                                                      <div className="cutom_plus">
                                                        <img src="../Assets/img/plus.png"></img>
                                                        <input
                                                          disabled={
                                                            eventTypeOf ==
                                                            "close_event"
                                                              ? true
                                                              : false
                                                          }
                                                          type="date"
                                                          id="date"
                                                          defaultValue={
                                                            task_data.due_date?.split(
                                                              "T"
                                                            )[0]
                                                          }
                                                          className="due_date"
                                                          style={{
                                                            border: "none",
                                                          }}
                                                          //   min={todayDate}
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          {sub_category_data
                                            .not_define_task_data?.length > 0 &&
                                            sub_category_data.not_define_task_data.map(
                                              (task_data, task_key) => {
                                                return (
                                                  <tr
                                                    className="deltask"
                                                    id={`task_id_${task_data._id}`}
                                                  >
                                                    <td
                                                      className="custom_ty6"
                                                      onClick={() => {
                                                        handleShow(
                                                          task_data._id,
                                                          false
                                                        );
                                                        // delete_task(
                                                        //   task_data._id,
                                                        //   false
                                                        // );
                                                      }}
                                                    >
                                                      <img
                                                        className="custom_ty66"
                                                        src="../Assets/img/delete.png"
                                                      />
                                                    </td>
                                                    <td className="custom_labe8">
                                                      <input
                                                        disabled={
                                                          eventTypeOf ==
                                                          "close_event"
                                                            ? true
                                                            : false
                                                        }
                                                        type="text"
                                                        placeholder="Task name"
                                                        className="task_name utm_custom_value"
                                                        name={`task_name[@${category_data._id}|${sub_category_data._id}|${task_data._id}|'update'|false@]`}
                                                        defaultValue={
                                                          task_data.name
                                                        }
                                                        id={`task_input_${task_data._id}`}
                                                        onChange={(e) => {
                                                          changeTaskName(
                                                            e.target.value,
                                                            task_data._id
                                                          );
                                                        }}
                                                      />
                                                      <img src="../Assets/img/edit.png" />
                                                    </td>
                                                    <td className="custom_DATE_PICKER">
                                                      <div
                                                        className="cutom_plus"
                                                        // onClick={() =>
                                                        //   setUserListing(true)
                                                        // }
                                                      >
                                                        <img src="../Assets/img/plus.png" />
                                                        <select
                                                          disabled={
                                                            eventTypeOf ==
                                                            "close_event"
                                                              ? true
                                                              : false
                                                          }
                                                          className="task-option user_id"
                                                        >
                                                          <option value="">
                                                            Choose a users
                                                          </option>
                                                          {userData.length >
                                                            0 &&
                                                            userData.map(
                                                              (
                                                                user_data,
                                                                user_key
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    value={
                                                                      user_data._id
                                                                    }
                                                                    selected={
                                                                      user_data._id ==
                                                                      task_data.user_assign_id
                                                                        ? "selected"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    {
                                                                      user_data
                                                                        .user_id
                                                                        ?.first_name
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                      </div>
                                                    </td>
                                                    <td className="custom_task_name">
                                                      <div className="cutom_plus">
                                                        <img src="../Assets/img/plus.png"></img>
                                                        <input
                                                          disabled={
                                                            eventTypeOf ==
                                                            "close_event"
                                                              ? true
                                                              : false
                                                          }
                                                          type="date"
                                                          id="date"
                                                          defaultValue={
                                                            task_data.due_date?.split(
                                                              "T"
                                                            )[0]
                                                          }
                                                          className="due_date"
                                                          style={{
                                                            border: "none",
                                                          }}
                                                          //   min={todayDate}
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                </>
              );
            })}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this task?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={delete_task}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

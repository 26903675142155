import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import { useCreateEventStep1Mutation } from "../Redux/Slices.js/EventSlice";

const Step3 = (props) => {
  const [createEventStep1] = useCreateEventStep1Mutation();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState("");
  const [event_name, setEventName] = useState("");
  const isAlphaNumeric = (value) => /^[a-zA-Z]+[a-zA-Z0-9 ]*$/.test(value);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (
      (isAlphaNumeric(inputValue) || inputValue === "") &&
      inputValue.length <= 30
    ) {
      setEventName(inputValue);
    }
  };
  const submitForm = () => {
    if (!event_name) {
      toast(
        "Event name must start with a letter and can only contain letters and numbers."
      );
    } else if (!isAlphaNumeric(event_name)) {
      toast(
        "Event name must start with a letter and can only contain letters and numbers."
      );
    } else if (event_name.length > 30) {
      toast("Event name must not exceed 30 characters.");
    } else {
      let event_data = {
        event_name: event_name,
        event_id: localStorage.getItem("event_id"),
      };
      createEventStep1(event_data)
        .then((response) => {
          navigate("/step4");
        })
        .catch((err) => {
          toast(err?.message);
        });
    }
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    if (!event_id) {
      navigate("/PricingPlans");
    }

    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        if (typeof get_event_data === "undefined") {
          navigate("/Dashboard");
        }
        setEventData(get_event_data);
        setEventName(get_event_data.event_name);
      });
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 3 of 7</div>
      </div>
      <section className="planing_outer">
        <div className="container">
          <Link to="/step2">
            {" "}
            <i
              style={{ fontSize: "50px" }}
              className="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>What's your event name?</h4>
          </div>
          <div className="row center_align">
            <div className="col-lg-10">
              <div className="input_outer">
                <input
                  type="text"
                  placeholder="Event Name"
                  name="event_name"
                  value={event_name}
                  onChange={handleInputChange}
                  // onChange={(e) => setEventName(e.target.value)}
                  autoComplete="off"
                />

                <br></br>
                <p className="p_text">You can always edit it later</p>
              </div>
            </div>
          </div>
          <div className="btn_outer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                submitForm();
              }}
            >
              {" "}
              Continue
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step3;

import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import SideBar from "../events/SideBar";
import EventDetails from "../events/EventDetails";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../Redux/Slices.js/AuthSlice";
import Loader from "../Component/Loader";
import { toast } from "react-toastify";

const Profile = () => {
  const { data: getProfile, refetch } = useGetProfileQuery();
  const [userDetail, setUserDetail] = useState([]);

  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [updateProfile] = useUpdateProfileMutation();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();

  const updateUser = async () => {
    setIsLoaderDisplay(true);

    let user_data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: number,
    };
    updateProfile(user_data)
      .then((response) => {
        toast("Your profile has been updated successfully.");
        refetch();
        setIsLoaderDisplay(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaderDisplay(false);
      });
  };
  const getUserDetail = async () => {
    setUserDetail(getProfile?.data);
  };

  useEffect(() => {
    getUserDetail();
  });

  return (
    <>
      {isLoaderDisplay ? <Loader /> : null}

      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>

      <section className="check_list">
        <SideBar />
        <div className="pofile_Cont mt-3">
          <div className="profl_div shadow">
            <div className="Profile_Details">
              <div>
                <Row className="mb-3"></Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="first_name">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      placeholder="First Name"
                      defaultValue={userDetail?.first_name}
                      // value={firstName}
                      id="first_name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="last_name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      placeholder="Last Name"
                      defaultValue={userDetail?.last_name}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      disabled
                      defaultValue={userDetail?.email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="phone_number">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Contact Number"
                      defaultValue={userDetail?.phone_number}
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Form.Group
                  className="mb-3"
                  controlId="formGridAddress1"
                ></Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    updateUser();
                  }}
                >
                  Update Data
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;

import { useEffect } from "react";
import axios from "axios";
import get_url from "../events/common/ApiCaller";
import { useNavigate } from "react-router-dom";
import { Get } from "../Services/services.midlware";
import { usePaymentSuccessQuery } from "../Redux/Slices.js/PricingPlanSlice";
import { toast } from "react-toastify";
const PaymentSuccess = () => {
  const navigate = useNavigate();
  let hasErrors = true;

  useEffect(() => {
    if (hasErrors === true) {
      userDetail();
      successSubscription();
    }
  }, [hasErrors]);

  const userDetail = async () => {
    hasErrors = false;
    await Get("userRoute/get-user").then((response) => {
      const userData = response.data.data;
      if ("undefined" === userData) {
        navigate("/Login");
      }
    });
  };

  const successSubscription = async () => {
    hasErrors = false;
    await Get("subscription/successCheckout").then((response) => {
      if (response.data.status !== 200) {
        toast(response.data.message);
        navigate("/Dashboard");
      }
      toast(response.data.message);
      navigate("/Step1");
    });
  };
};
export default PaymentSuccess;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";

const Step7 = () => {
  const navigate = useNavigate();
  const [budget, setBudget] = useState("");

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const submitForm = () => {
    if (!budget) {
      toast("Please enter budget amount.");
    } else {
      let event_data = {
        budget: budget,
        event_id: localStorage.getItem("event_id"),
      };
      axios
        .post(`${get_url.local_url}events/createEvent`, event_data, header)
        .then((response) => {
          navigate("/EventSummary");
        });
    }
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    if (event_id == null) {
      navigate("/PricingPlans");
    }
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        if (typeof get_event_data === "undefined") {
          navigate("/Dashboard");
        }
        setBudget(get_event_data.budget);
      });
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const onlyNumber = (event) => {
    // Replace any non-numeric characters with an empty string
    const value = event.target.value.replace(/[^0-9]/g, "");
    setBudget(value);
  };

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 7 of 7</div>
      </div>
      <section className="planing_outer">
        <div className="container">
          <Link to="/step6">
            {" "}
            <i
              style={{ fontSize: "50px" }}
              className="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>Total Budget</h4>
          </div>
          <div className="row center_align">
            <div className="col-lg-10">
              <div className="input_outer">
                <input
                  type="text"
                  placeholder="$"
                  name="budget"
                  value={budget}
                  onChange={onlyNumber}
                  maxLength={10}
                />
                <br></br>
                <p className="p_text"></p>
              </div>
            </div>
            <div className="btn_outer no_edit later3">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={submitForm}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step7;

import React, { useEffect, useState } from "react";
// import '../../../public/css/super.css';
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import DataTable from "react-data-table-component";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useGetEventDetailsQuery } from "../../Redux/Slices.js/SuperAdminSlice";
import { Button, Modal } from "react-bootstrap";

const Index = () => {
  const [data1, setData1] = useState([]);
  const [page, setPage] = useState(1);

  const { data, refetch } = useGetEventDetailsQuery();
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleDeleteEvent = (row) => {
    setSelectedEvent(row);
    setShowModal(true);
  };

  const deleteConfirmed = async () => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };

    const { _id } = selectedEvent;

    let formData = new FormData();
    formData.append("id", _id);

    try {
      await axios.delete(
        `${get_url.local_url}superAdmin/removeEvent/${selectedEvent?._id}`,
        headers
      );
      toast.success("Event deleted successfully");
      refetch();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowModal(false);
      setSelectedEvent(null);
    }
  };
  const columns = [
    {
      name: "#Sno.",
      cell: (row, index) => (page - 1) * 10 + index + 1, //RDT provides index by default
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name,
      sortable: true,
    },
    // {
    //   name: 'Email',
    //   selector: row => row.email,
    //   sortable: true,
    // },
    {
      name: "Event Start",
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Event End",
      selector: (row) => moment(row.end_date).format("DD-MM-YYYY"),
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "Event Size",
      selector: (row) => row.event_size,
    },
    {
      name: "Budget",
      selector: (row) => row.budget,
    },
    {
      name: "Delete",
      selector: (row, index) => (
        <button
          className=" btn action_btn"
          onClick={() => handleDeleteEvent(row)}
        >
          <i class="fas fa-trash"></i>
        </button>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  let datas = data;
  setTimeout(() => {
    if (data) {
      var eventData = datas?.data?.filter(function (el, key) {
        return "undefined" !== typeof el?.event_name && "" != el?.event_name;
      });
      setData1(eventData);
    }
  }, 100);

  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <AdminSideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list Page_box_subscription">
              <DataTable
                title="Events"
                columns={columns}
                data={data1}
                pagination
                highlightOnHover
                onChangePage={handlePageChange}
              />
            </div>
          </main>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the event "{selectedEvent?.event_name}
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;

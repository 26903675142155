import React, { useEffect, useState } from "react";
// import '../../../public/css/super.css';
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import DataTable from "react-data-table-component";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import AddTaskModal from "./addNewTask";
import { Post_Method, Get, Delete } from "../../Services/services.midlware";
import { Modal } from "react-bootstrap";

const Index = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [page, setPage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteEvent = (row) => {
    setSelectedTask(row);
    setShowDeleteModal(true);
  };

  const deleteConfirmed = async () => {
    try {
      const response = await Delete(
        `catSubCat/removeDefinedTask/${selectedTask._id}`
      );
      toast.success(response.data.message);
      getAllTask();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setShowDeleteModal(false);
      setSelectedTask(null);
    }
  };
  const columns = [
    {
      name: "#Sno.",
      cell: (row, index) => (page - 1) * 10 + index + 1, //RDT provides index by default
    },
    {
      name: "Category Name",
      selector: (row) => row.category_detail.name,
      sortable: true,
    },
    {
      name: "Sub Category Name",
      selector: (row) => row.sub_category.name,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row, index) => (
        <button
          className=" btn action_btn"
          onClick={() => handleDeleteEvent(row)}
        >
          <i class="fas fa-trash"></i>
        </button>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const getAllTask = async () => {
    await Get("catSubCat/definedTaskList").then((resp) => {
      setData(resp.data.data);
    });
    // const { data } = await axios.get(`${get_url.local_url}`)
    // console.log(data, 'herere')
  };

  useEffect(() => {
    getAllTask();
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <AdminSideBar />
          <div className="events_list">
            <div className="event_list_head su_btn_box">
              <div className="Subscription_btn">
                <Button variant="primary" onClick={handleShow}>
                  Add Task
                </Button>
              </div>
            </div>
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list Page_box_subscription">
              <DataTable
                title="Task"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                onChangePage={handlePageChange}
              />
            </div>
          </main>
        </div>
      </div>
      <AddTaskModal
        show={show}
        setShow={setShow}
        getAllTask={getAllTask}
        handleClose={handleClose}
      />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the category "{selectedTask?.name}
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;

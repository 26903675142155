import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const EventSlice = createApi({
  reducerPath: "EventSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    createEventStep1: builder.mutation({
      query: (data) => ({
        url: `events/createEvent`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
        },

        body: data,
      }),
    }),
    eventTypeList: builder.query({
      query: () => ({
        url: "events/eventTypeList",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
    eventDetails: builder.query({
      query: () => ({
        url: `events/eventDetails/${localStorage.getItem("event_id")}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const {
  useEventTypeListQuery,
  useCreateEventStep1Mutation,
  useEventDetailsQuery,
} = EventSlice;

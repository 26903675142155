import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import { toast } from "react-toastify";

const Step6 = (props) => {
  const navigate = useNavigate();

  const [isRadioSelect, setIsRadioSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  const [eventSize, setEventSize] = useState(["1-20", "21-50", "50+"]);

  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const handleChange = (value) => {
    setIsRadioSelect(true);
    localStorage.setItem("step5", value);
    props.setSelectedEventsEstimates(value);
    setSelectedValue(value);
  };

  const handleRadioButton = (eventMode) => {
    setSelectedValue(eventMode);
  };

  const submitForm = () => {
    if (selectedValue == 0) {
      toast("Please select event size first.");
    } else {
      let event_data = {
        event_size: selectedValue,
        event_id: localStorage.getItem("event_id"),
      };
      axios
        .post(`${get_url.local_url}events/createEvent`, event_data, header)
        .then((response) => {
          navigate("/step7");
        });
    }
  };

  const fetchEventData = async () => {
    let event_id = localStorage.getItem("event_id");
    if (event_id == null) {
      navigate("/PricingPlans");
    }
    await axios
      .get(`${get_url.local_url}events/eventDetails/${event_id}`)
      .then((response) => {
        const get_event_data = response.data.data;
        if (typeof get_event_data === "undefined") {
          navigate("/Dashboard");
        }
        if (get_event_data.event_size != undefined) {
          setSelectedValue(get_event_data.event_size);
        }
      });
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  return (
    <>
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 6 of 7</div>
      </div>
      <section className="planing_outer">
        <div className="container">
          <Link to="/step5">
            {" "}
            <i
              style={{ fontSize: "50px" }}
              class="fa-sharp fa-solid fa-arrow-left"
            ></i>
          </Link>
          <div className="inner_heading">
            <h4>How big is your event?</h4>
          </div>
          <div className="row">
            <div className="estimate-5">
              <div className="text_outer"># of Estimated Attendees</div>
              <div className="row">
                {eventSize.map((event, index) => (
                  <div className="col-lg-4 col-md-4" key={index}>
                    <div
                      className={`even_box1 ${
                        selectedValue === event ? "active-border" : ""
                      }`}
                      onClick={() => handleRadioButton(event)}
                    >
                      <label className="radiocontainer">
                        <input
                          type="radio"
                          className="ischeck prev_valued radio_data"
                          value={event}
                          name="event_size"
                          checked={selectedValue === event}
                          onChange={() => handleChange(event)}
                        />
                        <span className="checkmark"></span>
                        <h3>{event}</h3>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="btn_outer no_edit later3">
                <p className="p_text">You can always edit it later</p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitForm}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step6;

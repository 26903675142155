import React, { useEffect, useState, usePopup } from "react";
// import { useForm } from 'react-hook-form';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import get_url from "../events/common/ApiCaller";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import style from "../App/public/css/style.css";

import AppleLogin from "react-apple-login";
import {
  useSendVerifyMailMutation,
  useSignUpMutation,
} from "../Redux/Slices.js/AuthSlice";
import Loader from "../Component/Loader";

const SignUp = (props) => {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    // email: yup.string().email().required("email name is required"),
    first_name: yup
      .string()
      .required("First Name is required")
      .matches(/^[A-Za-z]+$/, "First name must contain only letters"),
    last_name: yup
      .string()
      .required("Last Name is required")
      .matches(/^[A-Za-z]+$/, "Last name must contain only letters"),
    // phone_number:yup.string().matches(new RegExp('[0-9]{10}')).required("Phone Number is required"),
    agree: yup
      .bool() // use bool instead of boolean
      .oneOf([true], "You must accept the terms and conditions"),
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [signUp] = useSignUpMutation();
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [phone, setPhone] = useState(false);
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const data1 = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      role: "admin",
      agree: data.agree,
    };
    signUp(data1)
      .then((response) => {
        toast(response?.error?.data?.data?.message);
        setLoading(false);
        if (response.data.statusCode == "200") {
          toast("User Registered Successfully Please Verify Your Email !");
          navigate("/Login");
          localStorage.setItem("login_token", response.token);
          localStorage.setItem("role", response.data.data.role);
        } else if (response?.error?.status == 422) {
          toast(response?.error?.data?.data?.message);
          // toast.error(response.data.message);
        } else {
          toast(response?.error?.data?.data?.message);
          // toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.data?.message);
        toast.error(err?.response?.data?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
    // reset();
  };

  const appleResponse = (response) => {
    if (!response.error) {
      axios
        .post(get_url.local_url + "auth", response)
        .then(
          (res) => {
            localStorage.setItem("login_token", res.data.token);
            localStorage.setItem("role", res.data.data.role);
            navigate("/Welcome");
          }
          // this.setState({ authResponse: res.data })
        )
        .catch((err) => toast(err?.message));
    }
  };

  return (
    <>
      {/* <MainHeader/> */}
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <div>
        <div className="signup_container shadow">
          <div className="image_con">
            <div className="motive_text overlay1">
              <h2 className="Motive_text_tell">Manage your events perfectly</h2>
            </div>
          </div>
          <div className="form_con">
            <div className="action_form">
              <h2>Sign Up</h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      className="F_name"
                      placeholder="First name"
                      name="first_name"
                      {...register("first_name", { required: true })}
                      autoComplete="off"
                    />
                    {errors.first_name && (
                      <span className="custom-error">
                        {errors.first_name.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="F_name"
                      placeholder="Last name"
                      name="last_name"
                      {...register("last_name", { required: true })}
                      autoComplete="off"
                    />
                    {errors.last_name && (
                      <span className="custom-error">
                        {errors.last_name.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      className="E_mail"
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      {...register("email")}
                      autoComplete="off"
                    />
                    <p className="custom-error">{errors.email?.message}</p>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Password"
                      name="password"
                      {...register("password", { required: true })}
                      maxlength="20"
                      autoComplete="off"
                    />
                    {errors.password && (
                      <span className="custom-error">
                        {errors.password?.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control
                      className="P_assword"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      {...register("confirm_password", { required: true })}
                      autoComplete="off"
                    />
                    {errors.confirm_password && (
                      <span className="custom-error">
                        {errors.confirm_password?.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3 Form_con_2" id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    onChange={checkboxHandler}
                    label="Agree to our Terms and conditions"
                    name="agree"
                    {...register("agree", { required: true })}
                    autoComplete="off"
                  />
                  {errors.agree && (
                    <span className="custom-error">
                      {errors.agree?.message}
                    </span>
                  )}
                </Form.Group>

                <Button className="btn button_mg" type="submit">
                  Create Account
                </Button>

                <div className="login_with_div">
                  <div className="more_login_text"> Or Login with</div>
                </div>
                <div className="Login_with_link" style={{ cursor: "pointer " }}>
                  <AppleLogin
                    clientId="app.easyevents.webapp"
                    scope="name email"
                    redirectURI="https://easyevents.app/SignUp"
                    callback={appleResponse}
                    responseMode="query"
                  />
                </div>
                <div className="not_account">
                  Already have an account? <Link to="/Login">Login</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;

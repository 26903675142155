import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";

const MainHeader = (props) => {
  const navigate = useNavigate();
  const [loginToken, setLoginToken] = useState(
    localStorage.getItem("login_token")
  );

  const handleLogout = () => {
    window.localStorage.clear();
    // props.resetEvents();
    toast.success("User Logout Successfully");
    navigate("/Login");
  };
  return (
    <>
      <header className="custom_header">
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <h5 className="easy_event">Easy Events</h5>
            </Link>
            <div className="toggle_outer">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Features
          </a>
          <ul className="dropdown-menu features-dropdown" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="#">Checklist</Link></li>
            <li><Link className="dropdown-item" to="#">Contacts</Link></li>
            <li><Link className="dropdown-item" to="#">Budget</Link></li>
          </ul>
        </li> */}
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/AboutUs">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/PricingPlans">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/BlogListings">
                    Blog
                  </Link>
                </li>
              </ul>
              <div
                className={`custom_tn8 ${loginToken == null ? "" : "d-none"}`}
                role="search"
              >
                <button className="btn btn-outline-success" type="submit">
                  {" "}
                  <Link to="/Login" className="logn_btn">
                    Log In
                  </Link>
                </button>
                <button className="btn btn-primary only_Li">
                  {" "}
                  <Link to="/SignUp" className="logn_btn2">
                    Sign Up
                  </Link>
                </button>
              </div>
              <div
                className={` col-lg-2 col-md-3 ${
                  loginToken == null ? "d-none" : ""
                }`}
              >
                <Dropdown className="mainheader_profile">
                  <Dropdown.Toggle
                    className="create_outer1 new_cre4 profile_toggle"
                    id="dropdown-basic"
                  >
                    <img
                      alt="User"
                      src="https://easyevents.app/Assets/img/Icon_Profile.png"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="Profile_Options">
                    <Dropdown.Item className="Profile_Option" href="#/action-1">
                      <Link
                        to="/Profile"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <i class="Profile_icons fas fa-user-circle"></i> Profile{" "}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="Profile_Option" href="#">
                      <Link
                        to="/Dashboard"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <i class="fa-solid fa-table-columns"></i> &nbsp;
                        Dashboard{" "}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="Profile_Option" href="#/action-2">
                      <Link
                        to="/ResetPassword"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <i class="Profile_icons fas fa-lock"></i> Change
                        Password
                      </Link>
                    </Dropdown.Item>

                    {/* <Link to="/Logout" onClick={handleLogout}>Logout</Link> */}
                    <Dropdown.Item
                      className="Profile_Option"
                      onClick={handleLogout}
                    >
                      <i class="Profile_icons fas fa-sign-out-alt"></i> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default MainHeader;

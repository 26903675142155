import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import get_url from "./common/ApiCaller";
import axios from "axios";
import { Get } from "../Services/services.midlware";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useCreateEventStep1Mutation,
  useEventDetailsQuery,
} from "../Redux/Slices.js/EventSlice";
import Loader from "../Component/Loader";
import { toast } from "react-toastify";

const Step1 = (props) => {
  const navigate = useNavigate();
  const [eventTypes, setEventTypes] = useState([]);
  const [radioButton, setRadioButton] = useState(false);
  const [updatedLists, setUpdatedLists] = useState("0");
  const [eventData, setEventData] = useState({});
  const [event, setEvent] = useState(); // 1 for business and 2 for personal
  const [createEventStep1] = useCreateEventStep1Mutation();
  const [loading, setLoading] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState(null);

  const header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };

  const handleRadioButton = (item_id, index) => {
    // Update the active state using React state, no need for jQuery
    const updatedEventTypes = eventTypes.map((item) => ({
      ...item,
      isActive: item._id === item_id,
    }));
    setEventTypes(updatedEventTypes); // Store the selected event type ID in state
    setSelectedEventType(item_id);
  };

  const submitForm = () => {
    setLoading(true);

    if (event === 0) {
      toast("Please choose either business or personal");
      setLoading(false);
      return false;
    }

    const checked_radio_id = eventTypes.find((item) => item.isActive);

    if (!checked_radio_id) {
      setLoading(false);
      toast("Please select any event type first.");
      return false;
    } else if (!event) {
      toast("Please choose either business or personal");
      setLoading(false);
      return false;
    } else {
      const event_data = {
        event_type: checked_radio_id._id,
        event: event,
        event_id: localStorage.getItem("event_id"),
      };
      createEventStep1(event_data)
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (
            localStorage.getItem("event_id") === null ||
            localStorage.getItem("event_id") === "undefined"
          ) {
            localStorage.setItem("event_id", response.data.data._id);
            localStorage.setItem("selectedEventType", checked_radio_id._id);

            navigate("/step2");
          } else if (response) {
            localStorage.setItem("selectedEventType", checked_radio_id._id);
            navigate("/step2");
          }
        })
        .catch((err) => {
          toast(err?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchEventType = async () => {
    await Get("events/eventTypeList").then((response) => {
      const get_radio_data = response.data.data;
      setEventTypes(get_radio_data);
    });
  };

  const { data: eventDetails } = useEventDetailsQuery();

  const fetchEventData = async () => {
    const event_id = localStorage.getItem("event_id");
    if (typeof event_id !== "undefined") {
      const get_event_data = eventDetails?.data;

      setEvent(get_event_data?.type_of_event);
      setEventData(get_event_data);
    }
  };

  const fetchUserData = async () => {
    await axios
      .get(`${get_url.local_url}userRoute/get-user`, header)
      .then((response) => {
        const userData = response.data.data;

        if (typeof userData._id === "undefined") {
          navigate("/Login");
        }
        if (
          (!localStorage.getItem("event_id") && userData?.event_count <= 0) ||
          userData?.event_count === undefined
        ) {
          navigate("/PricingPlans");
        }
      });
  };

  useEffect(() => {
    fetchEventData();
    fetchEventType();
    fetchUserData();
    // Fetch the selected event type ID from localStorage
    const storedEventType = localStorage.getItem("selectedEventType");
    if (storedEventType) {
      setSelectedEventType(storedEventType);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : null}
      <Header />
      <div className="footstep-outer">
        <div className="foot-step shadow-lg"> Step 1 of 7</div>
      </div>
      <div>
        <section className="planing_outer">
          <div className="container">
            <Link to="/">
              {" "}
              <i
                style={{ fontSize: "50px" }}
                className="fa-sharp fa-solid fa-arrow-left"
              ></i>
            </Link>
            <div className="inner_heading">
              <h4>What type of event are you planning?</h4>
            </div>
            <ul
              className="tabberHost mt-3 nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${event === 1 ? "active" : ""}`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => setEvent(1)}
                >
                  Business
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link disabled"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Or
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${event === 2 ? "active" : ""}`}
                  id="personal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#personal"
                  type="button"
                  role="tab"
                  aria-controls="personal"
                  aria-selected="false"
                  onClick={() => setEvent(2)}
                >
                  Personal
                </button>
              </li>
            </ul>
            <div className="row">
              {eventTypes.length > 0 &&
                eventTypes.map((item, index) => (
                  <div className="col-lg-4 col-md-4" key={item._id}>
                    <div
                      className={`planing_box1 checkList ${
                        item.isActive || selectedEventType === item._id
                          ? "active-border"
                          : ""
                      }`}
                      onClick={() => handleRadioButton(item._id, index)}
                    >
                      <input
                        name="event_type"
                        value={item._id}
                        type="radio"
                        className="get_previos_name"
                        id={`checkbox_id_${item._id}`}
                        checked={item.isActive}
                      />
                      <div>
                        <span className="not-checked-item">{item.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="Two_Choice mt-3">
                  <div
                    className="row"
                    aria-label="Basic radio toggle button"
                  ></div>
                </div>
              </div>
            </div>
            <div className="btn_outer">
              <p className="item_outer5"></p>
              <button
                className="btn btn-primary submit_button"
                onClick={(e) => {
                  submitForm();
                }}
              >
                Continue{" "}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Step1;

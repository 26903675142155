import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import get_url from "./common/ApiCaller";
import EventDetails from "./EventDetails";
import axios from "axios";
import SideBar from "./SideBar";
import $ from "jquery";
import Multiselect from "multiselect-react-dropdown";
import { ThreeDots } from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import moment from "moment";
import { Get, Delete, Patch } from "../Services/services.midlware";

const Contacts = (props) => {
  window.scrollTo(0, 0);

  const [showOne, setShowOne] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);

  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const handleCloseEditContact = () => setShowEditContact(false);

  /** Modal Hooks Start */

  const exampleInput = useRef();
  const rowsData = [];
  const [categoryList, setCategoryList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [getUpdateData, setgetUpdateData] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [isLoaderDisplay, setIsLoaderDisplay] = useState(false);
  const [contactListName, setContactListName] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const eventTypeOf = localStorage.getItem("event_type");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showContactListDeleteModal, setShowContactListDeleteModal] =
    useState(false);
  const [selectedcontactListTask, setSelectedcontactListTask] = useState(null);
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("login_token")}`,
      event_id: localStorage.getItem("event_id"),
      user_role: localStorage.getItem("role"),
    },
  };
  const handleDeleteEvent = (row) => {
    setSelectedTask(row);
    setShowDeleteModal(true);
  };
  const handleDeleteContactList = (row) => {
    setSelectedcontactListTask(row);
    setShowContactListDeleteModal(true);
  };
  const navigate = useNavigate();
  const userDetail = () => {
    axios
      .get(`${get_url.local_url}userRoute/get-user`, header)
      .then((response) => {
        const userData = response.data.data;
        if ("undefined" === userData) {
          navigate("/Login");
        }
        setUserDetails(userData);
      });
  };

  const delete_contact_list = async (contact_list_id) => {
    // if (window.confirm("Are you sure to delete this contact list.")) {
    await Delete(`contact/removeContactList/${selectedcontactListTask}`)
      .then((response) => {
        fetch_contact_list_data();
        setShowContactListDeleteModal(false);
        toast.success("Contact List Delete Successfully");
      })
      .catch((error) => console.log(error));
    // } else {
    //   setIsLoaderDisplay(false);
    // }
  };

  const delete_contact = async (contact_id) => {
    // if (window.confirm("Are you sure to delete this contact.")) {
    await Delete(`contact/remove/${selectedTask}`)
      .then((response) => {
        fetch_contact_list_data();
        toast.success("Contact Delete Successfully");

        setShowDeleteModal(false);
      })

      .catch((error) => console.log(error));
    // } else {
    //   setIsLoaderDisplay(false);
    // }
  };
  const [personName, setPersonName] = useState();
  const [cName, setCName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [category, setCategory] = useState();
  const [note, setNote] = useState();
  const [checkInDate, setCheckInDate] = useState();

  const add_contact = async (contact_list_id) => {
    const selected_category_id = categoryName.map((u) => u.id);
    let get_category = selected_category_id;
    const nameRegex = /^[a-zA-Z\s]{1,50}$/; // Allows only letters and spaces
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes a 10-digit phone number
    if ("" === personName || !nameRegex.test(personName)) {
      alert("Please enter a valid name of the person.");
      return false;
    }

    if ("" === cName || !nameRegex.test(cName)) {
      alert("Please enter a valid name of the company.");
      return false;
    }

    if ("" === email || !emailRegex.test(email)) {
      alert("Please enter a valid email of the person.");
      return false;
    }

    if ("" === number || !phoneRegex.test(number)) {
      alert("Please enter a valid phone number of the person.");
      return false;
    }

    if (undefined === checkInDate) {
      alert("Please enter check-in date.");
      return false;
    }

    let contact_data = {
      event_id: header.headers.event_id,
      name: personName,
      company_name: cName,
      email: email,
      phone_number: number,
      categories_id: get_category,
      notes: note,
      next_check_in: checkInDate,
      contact_type_id: contact_list_id,
    };

    setIsLoaderDisplay(true);

    await axios
      .post(`${get_url.local_url}contact/addContact`, contact_data)
      .then((response) => {
        fetch_contact_list_data();
        $(".field_for_empty").val("");
        setCategoryName([]);
        $("[data-bs-dismiss=modal]").trigger({ type: "click" });
      })
      .catch((error) => console.log(error));

    if (
      $("#contact_list_id_" + contact_list_id)
        .find(".checklist_head")
        .hasClass("collapsed")
    ) {
      $("#contact_list_id_" + contact_list_id)
        .find(".checklist_head")
        .trigger("click");
    }
  };
  const add_contact_list = async () => {
    let conatct_list_name = $("#contact_list_name").val().trim();

    if (contactListName.includes(conatct_list_name.toLowerCase())) {
      alert("Conact list name already in use.");
      return false;
    }

    if (conatct_list_name == "") {
      alert("Please enter contact list name.");
      return false;
    }

    if (conatct_list_name != "") {
      if (50 < conatct_list_name.length) {
        alert("Contact list name should be less than 50 characters.");
        return false;
      }

      setIsLoaderDisplay(true);
      let contact_list_data = {
        event_id: header.headers.event_id,
        name: conatct_list_name,
      };
      await axios
        .post(
          `${get_url.local_url}contact/createContactList`,
          contact_list_data,
          header
        )
        .then((response) => {
          fetch_contact_list_data();
          $("#contact_list_name").val("");

          handleCloseOne();
        })
        .catch((error) => console.log(error));
    }
  };

  const fetch_contact_list_data = async () => {
    setIsLoaderDisplay(true);
    await axios
      .get(`${get_url.local_url}contact/contactList/${header.headers.event_id}`)
      .then((response) => {
        const all_contact_list_data = response.data.data;
        setContactList(all_contact_list_data);
        setContactListName([]);
        setIsLoaderDisplay(false);
      })
      .catch((error) => console.log(error));
  };
  const getCategoryList = async () => {
    await Get("catSubCat/categoriesList")
      .then((response) => {
        const all_category_list_data = response.data.data;
        setCategoryList(all_category_list_data);
      })
      .catch((error) => console.log(error));
  };
  const handleSelect = (event) => {
    setCategoryName(event);
  };

  const handleRemove = (event) => {
    setCategoryName(event);
  };

  const getContact = async (contactId) => {
    if ("undefined" === typeof contactId || "" === contactId) {
      return false;
    }
    await axios
      .get(`${get_url.local_url}contact/contactbyId/${contactId}`, header)
      .then((response) => {
        if (200 !== response.data.statusCode) {
          alert(response.data.message);
          return false;
        }
        if (response.data.data.categories_id) {
          setCategoryName(response.data.data.categories_id);
        }

        setContactDetails(response.data.data);
        setShowEditContact(true);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactDetails({
      ...contactDetails,
      [name]: value,
    });
  };

  const updateContact = async (contactId) => {
    if ("undefined" === typeof contactId || "" === contactId) {
      return false;
    }

    const selected_category_id = categoryName.map((u) => u.id);
    const nameRegex = /^[a-zA-Z\s]{1,50}$/; // Allows only letters and spaces, up to 50 characters
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes a 10-digit phone number

    if ("" === contactDetails.name || !nameRegex.test(contactDetails.name)) {
      alert("Please enter a valid name of the person.");
      return false;
    }

    if (
      "" === contactDetails.company_name ||
      !nameRegex.test(contactDetails.company_name)
    ) {
      alert("Please enter a valid name of the company.");
      return false;
    }

    if ("" === contactDetails.email || !emailRegex.test(contactDetails.email)) {
      alert("Please enter a valid email of the person.");
      return false;
    }

    if (
      "" === contactDetails.phone_number ||
      !phoneRegex.test(contactDetails.phone_number)
    ) {
      alert("Please enter a valid phone number of the person.");
      return false;
    }

    if ("" === contactDetails.next_check_in) {
      alert("Please enter check-in date.");
      return false;
    }

    let contact_data = {
      name: contactDetails.name,
      company_name: contactDetails.company_name,
      email: contactDetails.email,
      phone_number: contactDetails.phone_number,
      categories_id: selected_category_id,
      notes: contactDetails.notes,
      next_check_in: contactDetails.next_check_in,
    };

    await Patch(`contact/updateContact/${contactId}`, contact_data)
      .then((response) => {
        fetch_contact_list_data();
        setShowEditContact(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetch_contact_list_data();
    getCategoryList();
    userDetail();
  }, []);

  const data = categoryList.map((item, index) => {
    if (
      localStorage.getItem("selected_category").includes(item._id) &&
      !getUpdateData.some(function (o) {
        return o["id"] === item._id;
      })
    ) {
      getUpdateData.push({ id: item._id, name: item.name });
    }
  });

  const listName = contactList.map((item, index) => {
    contactListName.push(item.name.toLowerCase());
  });

  const onlyNumber = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  };

  const [todayDate, setTodayDate] = useState(false);

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    setTodayDate(maxDate);
  });

  return (
    <div>
      <section className="get_start">
        <div className="container">
          <EventDetails />
        </div>
      </section>
      <section className="check_list">
        <SideBar />
        <div className="check_right">
          <div className="row">
            <div className="col-lg-9 col-md-6">
              <div className="assign_outer">
                <h2>Contacts</h2>
                <p>
                  Use this page to add and find contact information for your
                  event stakeholders
                </p>
              </div>
            </div>
          </div>
          <hr />
          <>
            {eventTypeOf == "close_event" ? null : (
              <div className="listy9" id="add_more">
                <a
                  href="#"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                  onClick={handleShowOne}
                >
                  New Contact List
                </a>
              </div>
            )}

            {contactList?.length > 0 &&
              contactList.map((contact_list_data, contact_list_key) => {
                return (
                  <div
                    className="accordion category_accordian"
                    id={`contact_list_id_${contact_list_data._id}`}
                  >
                    <div className={`item delitem`}>
                      <div className="item-header">
                        <h2 className="mb-0">
                          <div className="btn btn-link checklist_head">
                            <div className="title_head_outer1">
                              <span className="title_heading">
                                {contact_list_data.name}
                              </span>
                            </div>
                            <div className="right_options">
                              <div className="right90 mt-0">
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#add_contact_${contact_list_data._id}`}
                                >
                                  Add new contact
                                </Link>
                              </div>
                              <div>
                                <button
                                  className="btn btn-link checklist_head collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#show_hide_func_${contact_list_data._id}`}
                                  aria-expanded="false"
                                  aria-controls={"ar"}
                                >
                                  <i className="fa fa-angle-down down-btn"></i>
                                </button>
                              </div>
                              <div
                                className="delete_list"
                                onClick={() => {
                                  handleDeleteContactList(
                                    contact_list_data._id
                                  );
                                  // delete_contact_list(contact_list_data._id);
                                }}
                              >
                                <img
                                  className="delete-btn"
                                  src="../Assets/img/close_icon.png"
                                />
                              </div>
                            </div>
                          </div>
                        </h2>
                      </div>

                      {/* MODAL START */}
                      <div
                        className="modal custom_modal fade model_div"
                        id={`add_contact_${contact_list_data._id}`}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="utm_modalTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="utm_modalTitle">
                                {" "}
                                Add contact
                              </h5>
                              <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">
                                  {" "}
                                  <i class="fa-solid fa-xmark"></i>
                                </span>
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6">
                                  Person's Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder=" Person's Name"
                                    name=""
                                    id={`person_name_${contact_list_data._id}`}
                                    onChange={(e) => {
                                      setPersonName(e.target.value);
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6">
                                  Company Name
                                  <input
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Company Name"
                                    name=""
                                    id={`company_name_${contact_list_data._id}`}
                                    onChange={(e) => {
                                      setCName(e.target.value);
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Email
                                  <input
                                    type="email"
                                    className="form-control field_for_empty"
                                    placeholder="Email"
                                    name=""
                                    id={`email_${contact_list_data._id}`}
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Phone Number
                                  <input
                                    type="number"
                                    className="form-control field_for_empty"
                                    placeholder="Phone Number"
                                    name=""
                                    id={`phone_number_${contact_list_data._id}`}
                                    required
                                    // onKeyUp={(e) => {
                                    //   onlyNumber(e);
                                    // }}
                                    onChange={(e) => {
                                      setNumber(e.target.value);
                                    }}
                                    maxLength={10}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Categories
                                  <Multiselect
                                    className="form-control multi-select"
                                    options={getUpdateData}
                                    displayValue="name"
                                    name="categories_id[]"
                                    onSelect={(event) => {
                                      handleSelect(event);
                                    }}
                                    onRemove={(event) => {
                                      handleRemove(event);
                                    }}
                                    selectedValues={categoryName}
                                  />
                                </div>
                                <div className="col-md-6 mt-3 next_date">
                                  Next Check In Date
                                  <input
                                    type="date"
                                    className="form-control field_for_empty"
                                    placeholder="First Name"
                                    name=""
                                    id={`check_in_date_${contact_list_data._id}`}
                                    required
                                    min={todayDate}
                                    onChange={(e) => {
                                      setCheckInDate(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="col-md-12 mt-3">
                                  Notes
                                  <textarea
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Note"
                                    name=""
                                    id={`note_${contact_list_data._id}`}
                                    onChange={(e) => {
                                      setNote(e.target.value);
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="para_save_outer">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        add_contact(contact_list_data._id);
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* MODAL END */}

                      {/* EDIT CONTACT MODAL START */}
                      <Modal
                        show={showEditContact}
                        onHide={handleCloseEditContact}
                        //   backdrop="static"
                        keyboard={false}

                        //   size="lg"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="utm_modalTitle">
                                {" "}
                                Edit contact
                              </h5>
                              <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">
                                  {" "}
                                  <i
                                    class="fa-solid fa-xmark"
                                    onClick={handleCloseEditContact}
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6">
                                  Person's Name
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder=" Person's Name"
                                    name="name"
                                    id={`edit_person_name`}
                                    required
                                    defaultValue={contactDetails.name}
                                  />
                                </div>
                                <div className="col-md-6">
                                  Company Name
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Company Name"
                                    name="company_name"
                                    id={`edit_company_name`}
                                    required
                                    defaultValue={contactDetails.company_name}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Email
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    type="email"
                                    className="form-control field_for_empty"
                                    placeholder="Email"
                                    name="email"
                                    id={`edit_email`}
                                    required
                                    defaultValue={contactDetails.email}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Phone Number
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    id={`edit_phone_number`}
                                    required
                                    onKeyUp={(e) => onlyNumber(e)}
                                    maxLength={10}
                                    defaultValue={contactDetails.phone_number}
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  Categories
                                  <Multiselect
                                    className="form-control multi-select"
                                    options={getUpdateData}
                                    displayValue="name"
                                    name="edit_categories_id[]"
                                    onSelect={(event) => {
                                      handleSelect(event);
                                    }}
                                    onRemove={(event) => {
                                      handleRemove(event);
                                    }}
                                    selectedValues={categoryName}
                                  />
                                </div>
                                <div className="col-md-6 mt-3 next_date">
                                  Next Check In Date{" "}
                                  {moment(contactDetails.next_check_in).format(
                                    "DD/MM/YYYY"
                                  )}
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    defaultValue={contactDetails.next_check_in}
                                    type="date"
                                    className="form-control field_for_empty"
                                    placeholder="First Name"
                                    name="next_check_in"
                                    id={`edit_check_in_date`}
                                    required
                                    min={todayDate}
                                  />
                                </div>
                                <div className="col-md-12 mt-3">
                                  Notes
                                  <textarea
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    type="text"
                                    className="form-control field_for_empty"
                                    placeholder="Note"
                                    name="notes"
                                    id={`edit_note`}
                                    defaultValue={contactDetails.notes}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="para_save_outer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        updateContact(contactDetails._id);
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      {/* EDIT CONTACT MODAL END */}

                      <div
                        id={`show_hide_func_${contact_list_data._id}`}
                        className={`collapse`}
                        aria-labelledby={"head"}
                        data-parent={"#accor"}
                      >
                        <div className="t-p">
                          <div className="table-responsive">
                            <input
                              type="hidden"
                              name="checklist"
                              ref={exampleInput}
                            />
                            <table className="table custom_table">
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Person's Name</th>
                                  <th>Phone Number</th>
                                  <th>Email</th>
                                  <th>Company Name</th>
                                  <th>Next Check-in</th>
                                  <th>Categories</th>
                                  <th>Notes</th>
                                </tr>
                              </thead>
                              <tbody id={`add_task_${contact_list_data._id}`}>
                                <tr className="deltask">
                                  <td className="custom_ty6"></td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.first_name}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.phone_number}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8">
                                    <input
                                      type="text"
                                      placeholder=""
                                      className="task_name"
                                      value={userDetails.email}
                                      disabled
                                    />
                                  </td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                  <td className="custom_labe8"></td>
                                </tr>

                                {contact_list_data.contact_data.length > 0 &&
                                  contact_list_data.contact_data.map(
                                    (contact_data, task_key) => {
                                      return (
                                        <tr
                                          className="deltask"
                                          id={`contact_id_${contact_data._id}`}
                                        >
                                          <td className="custom_ty6">
                                            <img
                                              className="custom_ty66"
                                              src="../Assets/img/delete.png"
                                              onClick={() => {
                                                handleDeleteEvent(
                                                  contact_data._id
                                                );
                                                // delete_contact(
                                                //   contact_data._id
                                                // );
                                              }}
                                            />
                                            <i
                                              class="fas fa-pencil-alt custom_ty66"
                                              onClick={() => {
                                                getContact(contact_data._id);
                                              }}
                                            ></i>
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.name}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.phone_number}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.email}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.company_name}
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={
                                                contact_data.next_check_in
                                                  ? moment(
                                                      contact_data.next_check_in
                                                    ).format("DD/MM/YYYY")
                                                  : ""
                                              }
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={
                                                contact_data.categories_id &&
                                                contact_data.categories_id
                                                  .length > 0
                                                  ? contact_data.categories_id.map(
                                                      (curr) => curr.name
                                                    )
                                                  : ""
                                              }
                                              disabled
                                            />
                                          </td>
                                          <td className="custom_labe8">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className="task_name"
                                              value={contact_data.notes}
                                              disabled
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        </div>
      </section>

      <Modal
        show={showOne}
        onHide={handleCloseOne}
        //   backdrop="static"
        keyboard={false}
        //   size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new contact list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              Contact List Name
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                name=""
                id="contact_list_name"
                required
                maxLength={50}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="btn btn-primary text-right"
            onClick={() => {
              add_contact_list();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the contact?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={delete_contact}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete contact list */}
      <Modal
        show={showContactListDeleteModal}
        onHide={() => setShowContactListDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the contact List?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowContactListDeleteModal(false)}
          >
            Close
          </Button>
          <Button variant="danger" onClick={delete_contact_list}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Contacts;

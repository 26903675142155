import React, { useEffect, useState } from "react";
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import get_url from "../../events/common/ApiCaller";

const AdminResetPassword = () => {
  const navigate = useNavigate();
  const user_id = useLocation().search;
  const userID = new URLSearchParams(user_id).get("user");
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("tokens")}`,
      // "event_id" : localStorage.getItem('event_id'),
      // "user_role" : localStorage.getItem('role')
    },
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: yup
      .string()
      .required("Please Confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    if (data.password == data.confirm_password) {
      let reset_password_data = {
        password: data.password,
        confirm_password: data.confirm_password,
      };
      await axios
        .post(
          `${get_url.local_url}change-password`,
          reset_password_data,
          header
        )
        .then((response) => {
          if (response.data.status == "1") {
            toast.success("Password Reset Successfully");
            navigate("/Dashboard1");
          } else {
            toast.error(response.data.message);
          }
        });
      reset();
    } else {
      alert("Password and change password doestn't match");
    }
  };
  return (
    <>
      <AdminHeader />
      <div class="container-fluid">
        <div class="row">
          <AdminSideBar />
          <main class="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list Page_box">
              <div>
                <h3 className="Welcome_text">Change Password</h3>
              </div>
              <div className="form_con">
                <div className="action_form">
                  <h2>Create New password</h2>
                  <p className="highlight_form_text">
                    Please reset your password{" "}
                  </p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                          className="P_assword"
                          type="password"
                          placeholder="Password"
                          name="password"
                          {...register("password", { required: true })}
                          autoComplete="off"
                        />
                        <p className="custom-error">
                          {errors.password?.message}
                        </p>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                          className="P_assword"
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          {...register("confirm_password", { required: true })}
                          autoComplete="off"
                        />
                        <p className="custom-error">
                          {errors.confirm_password?.message}
                        </p>
                      </Form.Group>
                    </Row>

                    <Button className="btn button_mg" type="submit">
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AdminResetPassword;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../../config/GetUrl";

export const AuthSlice = createApi({
  reducerPath: "AuthSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `login`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },

        body: data,
      }),
    }),
    verificationMail: builder.mutation({
      query: (data) => ({
        url: `verifyMail`,
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("login_token")}`,
          Accept: "application/json",
        },
        body: data,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `forget-password`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },

        body: data,
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: `signup`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },

        body: data,
      }),
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `userRoute/update-user-data`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },

        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `change-password`,
        method: "POST",
        mode: "cors",
        Content_Type: "application/json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
        },

        body: data,
      }),
    }),
    getProfile: builder.query({
      query: () => ({
        url: "userRoute/get-user",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("login_token")}`,
          event_id: localStorage.getItem("event_id"),
          user_role: localStorage.getItem("role"),
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useVerificationMailMutation,
  useSignUpMutation,
  useChangePasswordMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useForgetPasswordMutation,
} = AuthSlice;

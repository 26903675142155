import React, { useState, useRef, useEffect, Component } from "react";
// import '../../../public/css/super.css';
import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "../Layout/AdminSidebar";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import get_url from "../../events/common/ApiCaller";
import axios from "axios";
import { Get, Delete } from "../../Services/services.midlware";
import { Button, Modal } from "react-bootstrap";

const Index = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserDelete = (row) => {
    setSelectedUser(row);
    setShowModal(true);
  };

  const deleteConfirmed = async () => {
    try {
      await Delete(`superAdmin/removeUser/${selectedUser._id}`);
      toast.success("User deleted successfully");
      getUsers();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setShowModal(false);
      setSelectedUser(null);
    }
  };

  const columns = [
    {
      name: "#Sno.",
      cell: (row, index) => (page - 1) * 10 + index + 1, //RDT provides index by default
    },
    {
      name: "Name",
      selector: (row) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Delete",
      selector: (row, index) => (
        <button
          className=" btn action_btn"
          onClick={() => handleUserDelete(row)}
        >
          <i class="fas fa-trash"></i>
        </button>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const getUsers = async () => {
    await Get("superAdmin/userList")
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => toast(err.response.data.message));
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminSideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 ">
            <div className="events_list Page_box_subscription">
              <DataTable
                title="Users"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                onChangePage={handlePageChange}
              />
            </div>
          </main>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the user with name "
          {selectedUser?.first_name} {selectedUser?.last_name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;

import axios from "axios";
import get_url from "../events/common/ApiCaller";

export function getToken() {
  if (localStorage.getItem("tokens")) {
    return localStorage.getItem("tokens");
  } else if(localStorage.getItem("login_token")){
    return localStorage.getItem("login_token")
  }
  else {
    return null;
  }
}

axios.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      // if token avaiable
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

//---------------------- ------------------get api -----------------------------
export async function Get(url, body, limit = 100, offset = 0) {
  try {
    var res = await axios.get(`${get_url.local_url}${url}`, body);
    return res;
  } catch (error) {
    throw error;
  }
}
export async function Post_Method(url, body) {
  try {
    var res = await axios.post(`${get_url.local_url}${url}`, body);
    return res;
  } catch (error) {
    throw error;
  }
}
export async function Delete(url, body) {
  try {
    var res = await axios.delete(`${get_url.local_url}${url}`, body);
    return res;
  } catch (error) {
    throw error;
  }
}
export async function Patch(url, body) {
  try {
    var res = await axios.patch(`${get_url.local_url}${url}`, body);
    return res;
  } catch (error) {
    throw error;
  }
}
